import { Autocomplete, Grid } from '@mui/material'
import { parseISO } from 'date-fns'
import { t } from 'i18next'

import { FigDatePickerMonth, getIsDateInFuture } from 'src/components/common/FigDatePicker'
import { FigMenuItem } from 'src/components/common/FigDropdown'
import FigTextField from 'src/components/common/FigTextField'
import { PaperComponent } from 'src/components/CreditRenewal/components/PaperComponent'
import { dateISOFormat } from 'src/utils/format'

interface OccupationEmployerInputProps {
  isEmployed: boolean
  occupation: string
  occupationData: any
  setOccupation: (occupation: string) => void
  filter: any
  employer: string
  setEmployer: (employer: string) => void
  isEmployedQC: boolean
  employmentStartDate: string
  setEmploymentStartDate: (date: string) => void
}

export const OccupationEmployerInput = (props: OccupationEmployerInputProps) => {
  const {
    isEmployed,
    occupation,
    occupationData,
    setOccupation,
    filter,
    employer,
    setEmployer,
    isEmployedQC,
    employmentStartDate,
    setEmploymentStartDate,
  } = props

  if (!isEmployed) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12} marginBottom={'16px'}>
        <Autocomplete
          data-testid="confirm-occupation-input"
          freeSolo
          disableClearable={!occupation?.length}
          options={occupationData}
          onChange={(_, newValue: any) => {
            setOccupation(newValue?.inputValue ?? newValue ?? '')
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: t('Occupation.occupation.add', { occupationName: params.inputValue }),
              })
            }

            return filtered
          }}
          value={occupation}
          getOptionLabel={option => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option
            }
            if (option.inputValue) {
              return option.inputValue
            }
            return option.title
          }}
          componentsProps={{
            popper: {
              modifiers: [
                {
                  name: 'flip',
                  enabled: false,
                },
                {
                  name: 'preventOverflow',
                  enabled: false,
                },
              ],
            },
          }}
          PaperComponent={PaperComponent as any}
          renderOption={(props: object, option: any, _state: any, _ownerState: object) => (
            <FigMenuItem {...props}>
              <p style={{ whiteSpace: 'pre-wrap', margin: 0 }}>{option?.title ?? option}</p>
            </FigMenuItem>
          )}
          renderInput={params => (
            <FigTextField
              {...params}
              onChange={e => {
                setOccupation(e.target.value)
              }}
              label={t('CreditRenewals.ConfirmInformation.occupationInput.label')}
              placeholder={t('Occupation.occupation.placeholder')}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} marginBottom={'16px'}>
        <FigTextField
          onChange={e => {
            setEmployer(e.target.value)
          }}
          data-testid="confirm-employer-name-input"
          label={t(isEmployedQC ? 'Occupation.employer.labelQC' : 'Occupation.employer.label')}
          placeholder={t('Occupation.employer.placeholder')}
          value={employer}
        />
      </Grid>
      {isEmployedQC && (
        <Grid item xs={12} marginBottom={'16px'}>
          <FigDatePickerMonth
            label={t('Occupation.duration.label')}
            getIsInvalidDate={getIsDateInFuture}
            setCalendarDate={(d: Date) => setEmploymentStartDate(dateISOFormat(d))}
            calendarDate={employmentStartDate ? parseISO(employmentStartDate) : null}
            disableWeekends={false}
          />
        </Grid>
      )}
    </Grid>
  )
}
