import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import TrustBox from 'src/components/EndToEnd/landing/Walmart/components/TrustBox'
import { ImgStyle, MobileDivider } from 'src/components/EndToEnd/landing/Walmart/styles'
import { reviewAvatar } from 'src/images'

export const TrustpilotSection = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid
      container
      sx={{ backgroundColor: theme.palette.info.main, py: '64px', px: isMobile ? '32px' : 0 }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Grid container maxWidth="800px">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={3} md={3} textAlign={'right'}>
              <ImgStyle
                src={reviewAvatar}
                sx={isMobile ? { marginRight: 0 } : { marginLeft: '45%' }}
              />
            </Grid>
            <Grid item xs={9} md={9}>
              <Typography
                variant="sectionHeading"
                fontStyle={'italic'}
                sx={{ fontSize: isMobile ? '16px' : '24px', lineHeight: '24px' }}
              >
                {t('e2e.Landing.walmart.trustpilotSection.reviewer.text')}
              </Typography>
              <Typography variant="subheading" fontStyle={'italic'} fontWeight={400}>
                {t('e2e.Landing.walmart.trustpilotSection.reviewer.name')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} alignItems={'center'} justifyContent={'center'}>
          <TrustBox />
        </Grid>
      </Grid>
      <MobileDivider />
    </Grid>
  )
}
