import { ThemeProvider } from '@emotion/react'
import { Grid } from '@mui/material'

import WalmartLandingTheme from 'src/themes/WalmartLandingTheme'

export const WalmartLandingWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={WalmartLandingTheme}>
      <Grid container justifyContent="center" alignItems="center">
        {children}
      </Grid>
    </ThemeProvider>
  )
}
