export enum WalmartLandingPageSections {
  Header = 'Header',
  Banner = 'Banner',
  OfferSection = 'OfferSection',
  TaglineSection = 'TaglineSection',
  WhySection = 'WhySection',
  CardSection = 'CardSection',
  HowSection = 'HowSection',
  TrustpilotSection = 'TrustpilotSection',
  FAQSection = 'FAQSection',
  TermsSection = 'TermsSection',
}

export interface ApplyNowButtonProps {
  sectionName: WalmartLandingPageSections
  size: ApplyNowButtonSizes
}

export enum ApplyNowButtonSizes {
  SMALL = '150px',
  LARGE = '339px',
}
