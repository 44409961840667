import { Link } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'

import { StepsEnum } from 'src/types'
import { FRESHCHAT_CHANNEL_NAME } from 'src/utils/constants'

const figEmail = 'support@fig.ca'

export const openChat: () => void = () => window.fcWidget?.open({ name: FRESHCHAT_CHANNEL_NAME })
export const LiveChatLink = ({
  screen,
  analyticsCallback,
}: {
  screen: StepsEnum
  analyticsCallback: (object: string, page_name: StepsEnum) => void
}) => {
  const { t } = useTranslation()
  const figChatLabel = t('PleaseReachOut.liveChatLabel')
  return (
    <Link
      whiteSpace="nowrap"
      onClick={() => {
        analyticsCallback(figChatLabel, screen)
        openChat()
      }}
    >
      {figChatLabel}
    </Link>
  )
}

export const ContactFigLink = ({
  screen,
  analyticsCallback,
}: {
  screen: StepsEnum
  analyticsCallback: (object: string, page_name: StepsEnum) => void
}) => {
  const { t } = useTranslation()
  const contactFigLabel = t('PleaseReachOut.contactFigLabel')
  return (
    <Link
      href={`mailto:${figEmail}`}
      target="_blank"
      variant="linkMedium"
      whiteSpace="nowrap"
      onClick={() => {
        analyticsCallback(contactFigLabel, screen)
      }}
    >
      {contactFigLabel}
    </Link>
  )
}

export const PleaseReachOut = ({
  screen,
  liveChatAnalytics,
  contactFigAnalytics,
}: {
  screen: StepsEnum
  liveChatAnalytics: (object: string, page_name: StepsEnum) => void
  contactFigAnalytics: (object: string, page_name: StepsEnum) => void
}) => {
  return (
    <Trans i18nKey="PleaseReachOut.content">
      please reach out to us via{' '}
      <LiveChatLink screen={screen} analyticsCallback={liveChatAnalytics} /> to the bottom right of
      this page or <ContactFigLink screen={screen} analyticsCallback={contactFigAnalytics} />.
    </Trans>
  )
}
