import { AnalyticsBrowser } from '@segment/analytics-next'
import * as Sentry from '@sentry/react'

import { StepsEnum } from 'src/types'
import { ENVIRONMENT, SEGMENT_WRITE_KEY } from 'src/utils/constants'
import log from 'src/utils/log'

interface SegmentAnalytics {
  identify: (event: any, params: any) => void
  track: (event: any, params: any) => void
  page: (params?: any) => void
  group: () => void
}

const loadSegment = (): SegmentAnalytics => {
  // If for some reason segment isn't loading don't crash the app
  try {
    if (ENVIRONMENT && SEGMENT_WRITE_KEY) {
      return AnalyticsBrowser.load({ writeKey: SEGMENT_WRITE_KEY })
    }
  } catch (e) {
    Sentry.captureMessage(`Segment did not load due to ${e}`, 'warning')
  }

  return {
    identify: (event: any, params: any) => log.info('segment', 'identify', event, params),
    track: (event: any, params: any) => log.info('segment', 'track', event, params),
    page: (properties: any) => log.info('segment', 'page', properties),
    group: () => log.info('segment', 'group'),
  }
}

// This object is only set on local testing, staging, preprod and production
export const segmentAnalytics: SegmentAnalytics = loadSegment()

export enum SEGMENT_CONSTANTS {
  PAYMENT_PLAN_CONTINUE_CLICKED = 'PaymentPlan / Continue Clicked',
  QC_CONSENTS_BIOMETRIC_CONSENT_CLICKED = 'QCConsents / Biometric Consent Clicked',
  QC_CONSENTS_VIEW_MORE_CLICKED = 'QCConsents / View More Clicked',
  LOAN_OFFER_AMOUNT_CHANGED = 'SelectLoanOffer / Amount Changed',
  SELECT_OFFER_CHANGED = 'SelectOffer / Offer Changed',
  PRIVACY_POLICY_CLICKED = 'SelectLoanOffer / Privacy Policy Clicked',
  SELECT_LOAN_OFFER_AMOUNT_CONFIRMED = 'SelectLoanOffer / Amount Confirmed',
  SELECT_OFFER_MARKETING_CONSENT_CHECKED = 'SelectOffer / Marketing Consent Checked',
  SELECT_OFFER_CONFIRMED = 'SelectOffer / Offer Confirmed',
  SELECT_OFFER_BACK_CLICKED = 'SelectOffer / Back Clicked',
  CONNECT_BANK_CONNECT_BANK_ACCOUNT_CLICKED = 'ConnectBankAcct / Connect Bank Account Clicked',
  BANK_ERROR_CONNECT_BANK_ACCOUNT_CLICKED = 'BankConnectionFailed / Connect Bank Account Clicked',
  SELECT_EXISTING_CONTINUE_CLICKED = 'SelectExistingAccount / Continue Clicked',
  SELECT_EXISTING_ADD_BANK_ACCOUNT_CLICKED = 'SelectExistingAccount / Add Bank Account Clicked',
  SELECT_EXISTING_METHOD_BACK = 'SelectExistingAccount / Back Clicked',
  BANK_ERROR_LIVE_CHAT_CLICKED = 'BankConnectionFailed / Live Chat Clicked',
  BANK_ERROR_CONTACT_FIG_CLICKED = 'BankConnectionFailed / Contact Fig Clicked',
  CONNECT_BANK_BACK_CLICKED = 'ConnectBankAcct / Back Clicked',
  CONNECT_BANK_GUIDANCE_FAQ_CAROUSEL_VIEWED = 'ConnectBankAcct / FAQ Carousel Viewed',
  CONNECT_BANK_ACCOUNT_FLINKS_EVENT_POSTED = 'ConnectBankAcct / Flinks Event Posted',
  CONNECT_BANK_ACCOUNT_LEARN_MORE_CLICKED = 'ConnectBankAcct / Learn More Clicked',
  CONNECT_BANK_ACCOUNT_TOOL_TIP_VIEWED = 'ConnectBankAcct / Tooltip Viewed',
  CONFIRM_PAYMENT_SCHEDULE_BACK_CLICKED = 'ConfirmPaymentSchedule / Back Clicked',
  CONFIRM_PAYMENT_SCHEDULE_CHANGE_PAYMENT_SCHEDULE_CLICKED = 'ConfirmPaymentSchedule / Change Payment Schedule Clicked',
  PAD_AGREEMENT_DOWNLOADED = 'Review / PAD Agreement Downloaded',
  CONFIRM_PAYMENT_SCHEDULE_CLICKED = 'ConfirmPaymentSchedule / Payment Schedule Confirmed',
  SAVE_SCHEDULE_CLICKED = 'PaymentSchedule / Save Schedule Clicked',
  SCHEDULE_CANCEL_CLICKED = 'PaymentSchedule / Cancel Clicked',
  FLINKS_FAIL_LIVE_CHAT_CLICKED = 'FailFlinks / Live Chat Click',
  FLINKS_FAIL_CONTACT_FIG_CLICKED = 'FailFlinks / Contact Fig Click',
  FLINKS_FAIL_TRY_AGAIN_CLICKED = 'FailFlinks / Try Again Click',
  FLINKS_INVALID_BANK_FAIL_LIVE_CHAT_CLICKED = 'FailFlinksInvalidBank / Live Chat Click',
  FLINKS_INVALID_BANK_FAIL_CONTACT_FIG_CLICKED = 'FailFlinksInvalidBank / Contact Fig Click',
  FLINKS_INVALID_BANK_FAIL_TRY_AGAIN_CLICKED = 'FailFlinksInvalidBank / Try Again Click',
  KYC_ID_FAIL_LIVE_CHAT_CLICKED = 'KYC ID / Live Chat Click',
  KYC_ID_FAIL_CONTACT_FIG_CLICKED = 'KYC ID / Contact Fig Click',
  CHANGE_PAYMENT_METHOD_CLICKED = 'Review / Change Payment Method Clicked',
  CHANGE_PAYMENT_PROTECTION_PLAN_CLICKED = 'Review / Change Payment Protection Plan Clicked',
  REVIEW_CHANGE_PAYMENT_SCHEDULE_CLICKED = 'Review / Change Payment Schedule Clicked',
  LOAN_AGREEMENT_DOWNLOADED = 'Review / Loan Agreement Downloaded',
  REVIEW_LEARN_MORE_CLICKED = 'Review / Learn More Clicked',
  LOAN_AGREEMENT_CHECKED = 'Review / Loan Agreement Checked',
  PAD_AGREEMENT_CHECKED = 'Review / PAD Agreement Checked',
  HARD_CREDIT_CHECK_CHECKED = 'Review / Hard Credit Check Checked',
  REVIEW_MARKETING_CONSENT_CHECKED = 'Review / Marketing Consent Checked',
  CHANGE_LOAN_DETAILS_CLICKED = 'Review / Change Loan Details Clicked',
  CONFIRM_LOAN_CLICKED = 'Review / Book Loan Clicked',
  REVIEW_BACK_CLICKED = 'Review / Back Clicked',
  ORDER_DECLINED_HELP_CENTRE_CLICKED = 'Order Declined / Help Centre Clicked',
  ORDER_DECLINED_LIVE_CHAT_CLICKED = 'Order Declined / Live Chat Clicked',
  ORDER_DECLINED_CONTACT_FIG_CLICKED = 'Order Declined / Contact Fig Clicked',
  HELP_CENTRE_CLICKED = 'Confirmation / Help Centre Clicked',
  GO_TO_PARTNER_CLICKED = 'Confirmation / Go to Partner Clicked',
  START_AGAIN_WITH_PARTNER = 'OfferExpired / Start again with Partner',
  OFFER_EXPIRED_LIVE_CHAT_CLICKED = 'OfferExpired / Live Chat Clicked',
  OFFER_EXPIRED_CONTACT_FIG_CLICKED = 'OfferExpired / Contact Fig Clicked',
  EXISTING_CUSTOMER_LOGIN_CLICKED = 'ExistingCustomer / Log in Clicked',
  EXISTING_CUSTOMER_HELP_CENTRE_CLICKED = 'ExistingCustomer / Help Centre Clicked',
  EXISTING_CUSTOMER_LIVE_CHAT_CLICKED = 'ExistingCustomer / Live Chat Clicked',
  EXISTING_CUSTOMER_CONTACT_FIG_CLICKED = 'ExistingCustomer / Contact Fig Clicked',
  DECLINED_USER_HELP_CENTRE_CLICKED = 'DeclinedUser / Help Centre Clicked',
  DECLINED_USER_LIVE_CHAT_CLICKED = 'DeclinedUser / Live Chat Clicked',
  DECLINED_USER_CONTACT_FIG_CLICKED = 'DeclinedUser / Contact Fig Clicked',
  PURPOSE_OF_LOAN_BACK_CLICKED = 'PurposeOfLoan / Back Clicked',
  PURPOSE_OF_LOAN_INVESTMENT_SCAM_ARTICLE_LINK_CLICKED = 'PurposeOfLoan / Investment Scam Article Link Clicked',
  PURPOSE_PROVIDED = 'PurposeOfLoan / Purpose of Loan Provided',
  OCCUPATION_BACK_CLICKED = 'Occupation / Back Clicked',
  OCCUPATION_PROVIDED = 'Occupation / Occupation Provided',
  KYC_SUCCESS_CONTINUE_CLICKED = 'KYC Success / Continue Clicked',
  KYC_RETRY_GENERIC_CLICKED = 'KYC Retry / Re-Verify ID Clicked',
  KYC_RETRY_IMAGE_QUALITY_CLICKED = 'KYC Retry / Image Quality Re-Verify Clicked',
  KYC_RETRY_PII_CLICKED = 'KYC Retry / PII Re-Verify Clicked',
  KYC_RETRY_PII_LIVE_CHAT_CLICKED = 'KYC Retry / PII Live Chat Clicked',
  KYC_RETRY_PII_CONTACT_FIG_CLICKED = 'KYC Retry / PII Contact Fig Clicked',
  KYC_LOADING_PAGE_DECLINED = 'KYC Loading / Application Declined',
  TOS_DECLINED_TRY_AGAIN_CLICKED = 'TosDeclined / Try Again Clicked',
  TOS_DECLINED_LIVE_CHAT_CLICKED = 'TosDeclined / Live Chat Clicked',
  TOS_DECLINED_CONTACT_FIG_CLICKED = 'TosDeclined / Contact Fig Clicked',
  ONFIDO_EVENT_POSTED = 'VerifyOnfido / Onfido Event Posted',
  ONFIDO_USER_EXIT = 'VerifyOnfido / User Exited Flow',
  ONFIDO_POLLING_ONFIDO = 'VerifyOnfido / Polling Onfido',
  ERROR_TRY_AGAIN_CLICKED = 'Error / Try Again Clicked',
  ERROR_LIVE_CHAT_CLICKED = 'Error / Live Chat Clicked',
  ERROR_CONTACT_FIG_CLICKED = 'Error / Contact Fig Clicked',
  ERROR_VIEWED_VIA_ERROR_BOUNDARY = 'Error / Viewed via Error Boundary',
  PAGE_NOT_FOUND_TRY_AGAIN_CLICKED = 'PageNotFound / Try Again Clicked',
  PAGE_NOT_FOUND_LIVE_CHAT_CLICKED = 'PageNotFound / Live Chat Clicked',
  PAGE_NOT_FOUND_CONTACT_FIG_CLICKED = 'PageNotFound / Contact Fig Clicked',
  NO_APP_ID_CONTACT_FIG_CLICKED = 'ApplicationIdNotFound / Contact Fig Clicked',
  NO_APP_ID_HELP_CENTRE_CLICKED = 'ApplicationIdNotFound / Help Centre Clicked',
  LANDING_PAGE_CTA_CLICKED = 'LandingPage / E2E Landing Page Campaign CTA Clicked',
  WALMART_LANDING_PAGE_CTA_CLICKED = 'Walmart LandingPage / E2E Landing Page Campaign CTA Clicked',
  SECOND_LOOK_OFFER_PAGE_CTA_CLICKED = 'Second Look Offer Page CTA Clicked',
  PAYMENT_PROTECTION_ACCEPT_CLICKED = 'PaymentProtection / Accept Clicked',
  PAYMENT_PROTECTION_DECLINE_CLICKED = 'PaymentProtection / Decline Clicked',
  CREDIT_RENEWALS_ELIGIBILITY_PAGE_LOADED = 'CreditRenewals / Eligibility Page Loaded',
  CREDIT_RENEWALS_ELIGIBILITY_PAGE_CTA_CLICKED = 'CreditRenewals / Eligibility Page CTA Clicked',
  CREDIT_RENEWALS_DECLINED_PAGE_LOADED = 'CreditRenewals / Declined Page Loaded',
  CREDIT_RENEWALS_CONFIRM_INFORMATION_PAGE_LOADED = 'CreditRenewals / Confirm Information Page Loaded',
  CREDIT_RENEWALS_CONFIRM_INFORMATION_PAGE_CTA_CLICKED = 'CreditRenewals / Confirm Information Page CTA Clicked',
}

export const getAnalyticsCallback =
  (segmentName: string) =>
  (object: string, page_name: StepsEnum, action = 'Clicked', extra?: Record<string, any>) =>
    segmentAnalytics.track(segmentName, {
      object,
      page_name,
      action,
      ...extra,
    })

export const paymentPlanContinueClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.PAYMENT_PLAN_CONTINUE_CLICKED,
)

export const selectOfferConfirmed = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.SELECT_OFFER_CONFIRMED, {
    object: 'Continue',
    page_name: screen,
    action: 'Clicked',
  })
}

export const selectLoanOfferAmountConfirmed = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.SELECT_LOAN_OFFER_AMOUNT_CONFIRMED, {
    object: 'Continue',
    page_name: screen,
    action: 'Clicked',
  })
}

export const selectOfferBackClicked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.SELECT_OFFER_BACK_CLICKED, {
    object: 'Back',
    page_name: screen,
    action: 'Clicked',
  })
}

export const reviewBackClicked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.REVIEW_BACK_CLICKED, {
    object: 'Back',
    page_name: screen,
    action: 'Clicked',
  })
}

export const loanOfferTrackAmountChanged = (amount: number | number[]) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.LOAN_OFFER_AMOUNT_CHANGED, {
    amount,
    object: 'Amount',
    action: 'Clicked',
    page_name: StepsEnum.LOAN_AMOUNT,
  })
}

export const privacyPolicyDownloaded = (actionText: string) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.PRIVACY_POLICY_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: StepsEnum.LOAN_AMOUNT,
  })
}

export const selectOfferChanged = (termLength: string) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.SELECT_OFFER_CHANGED, {
    object: termLength,
    action: 'Clicked',
    page_name: StepsEnum.SELECT_OFFER,
  })
}

export const selectOfferMarketingConsentChecked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.SELECT_OFFER_MARKETING_CONSENT_CHECKED, {
    object: 'Marketing Consent',
    action: 'Clicked',
    page_name: screen,
  })
}

export const reviewMarketingConsentChecked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.REVIEW_MARKETING_CONSENT_CHECKED, {
    object: 'Marketing Consent',
    action: 'Clicked',
    page_name: screen,
  })
}

export const confirmPaymentScheduleBackClicked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CONFIRM_PAYMENT_SCHEDULE_BACK_CLICKED, {
    object: 'Back',
    action: 'Clicked',
    page_name: screen,
  })
}

export const connectBankConnectBankAccountClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CONNECT_BANK_CONNECT_BANK_ACCOUNT_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const connectBankGuidanceFAQCarouselViewed = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CONNECT_BANK_GUIDANCE_FAQ_CAROUSEL_VIEWED, {
    object: actionText,
    action: 'Viewed',
    page_name: screen,
  })
}

export const connectBankFlinksEventPosted = getAnalyticsCallback(
  SEGMENT_CONSTANTS.CONNECT_BANK_ACCOUNT_FLINKS_EVENT_POSTED,
)

export const bankErrorConnectBankAccountClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.BANK_ERROR_CONNECT_BANK_ACCOUNT_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const bankConnectCarouselLearnMoreClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CONNECT_BANK_ACCOUNT_LEARN_MORE_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const bankConnectCarouselTooltipViewed = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CONNECT_BANK_ACCOUNT_TOOL_TIP_VIEWED, {
    object: actionText,
    action: 'Viewed',
    page_name: screen,
  })
}

export const selectExistingContinueClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.SELECT_EXISTING_CONTINUE_CLICKED,
)

export const selectExistingAddBankAccountClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.SELECT_EXISTING_ADD_BANK_ACCOUNT_CLICKED,
)

export const selectExistingBackClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.SELECT_EXISTING_METHOD_BACK,
)

export const bankErrorLiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.BANK_ERROR_LIVE_CHAT_CLICKED,
)

export const bankErrorContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.BANK_ERROR_CONTACT_FIG_CLICKED,
)

export const connectBankAccountBackClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CONNECT_BANK_BACK_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const padAgreementDownloaded = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.PAD_AGREEMENT_DOWNLOADED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const confirmScheduleChangePaymentScheduleClicked = (
  actionText: string,
  screen: StepsEnum,
) => {
  segmentAnalytics.track(
    SEGMENT_CONSTANTS.CONFIRM_PAYMENT_SCHEDULE_CHANGE_PAYMENT_SCHEDULE_CLICKED,
    {
      object: actionText,
      action: 'Clicked',
      page_name: screen,
    },
  )
}

export const reviewChangePaymentScheduleClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.REVIEW_CHANGE_PAYMENT_SCHEDULE_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const saveScheduleClicked = (
  actionText: string,
  screen: StepsEnum,
  payment_frequency: string,
) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.SAVE_SCHEDULE_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
    payment_frequency,
  })
}

export const scheduleCancelClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.SCHEDULE_CANCEL_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const scheduleContinueClicked = (
  actionText: string,
  screen: StepsEnum,
  payment_frequency: string,
) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CONFIRM_PAYMENT_SCHEDULE_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
    payment_frequency,
  })
}

export const flinksFailLiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.FLINKS_FAIL_LIVE_CHAT_CLICKED,
)

export const flinksFailContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.FLINKS_FAIL_CONTACT_FIG_CLICKED,
)

export const flinksFailTryAgainClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.FLINKS_FAIL_TRY_AGAIN_CLICKED,
)

export const flinksInvalidBankFailLiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.FLINKS_INVALID_BANK_FAIL_LIVE_CHAT_CLICKED,
)

export const flinksInvalidBankFailContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.FLINKS_INVALID_BANK_FAIL_CONTACT_FIG_CLICKED,
)

export const flinksInvalidBankFailTryAgainClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.FLINKS_INVALID_BANK_FAIL_TRY_AGAIN_CLICKED,
)

export const kycIdFailLiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.KYC_ID_FAIL_LIVE_CHAT_CLICKED,
)

export const kycIdFailContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.KYC_ID_FAIL_CONTACT_FIG_CLICKED,
)

export const kycSuccessContinueClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.KYC_SUCCESS_CONTINUE_CLICKED,
)

export const changeLoanDetailsClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CHANGE_LOAN_DETAILS_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const changePaymentMethodClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CHANGE_PAYMENT_METHOD_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const changePaymentProtectionPlanClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CHANGE_PAYMENT_METHOD_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const loanAgreementDownloaded = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.LOAN_AGREEMENT_DOWNLOADED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const reviewLearnMoreClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.REVIEW_LEARN_MORE_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const loanAgreementChecked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.LOAN_AGREEMENT_CHECKED, {
    object: 'Loan Agreement',
    action: 'Clicked',
    page_name: screen,
  })
}

export const padAgreementChecked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.PAD_AGREEMENT_CHECKED, {
    object: 'PAD Agreement',
    action: 'Clicked',
    page_name: screen,
  })
}

export const hardCreditCheckChecked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.HARD_CREDIT_CHECK_CHECKED, {
    object: 'Hard Credit Check',
    action: 'Clicked',
    page_name: screen,
  })
}

export const confirmLoanClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.CONFIRM_LOAN_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const helpCentreClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.HELP_CENTRE_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const goToPartnerClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.GO_TO_PARTNER_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const startAgainWithPartner = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.START_AGAIN_WITH_PARTNER, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const offerExpiredLiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.OFFER_EXPIRED_LIVE_CHAT_CLICKED,
)

export const offerExpiredContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.OFFER_EXPIRED_CONTACT_FIG_CLICKED,
)
export const existingCustomerLoginClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.EXISTING_CUSTOMER_LOGIN_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const existingCustomerHelpCentreClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.EXISTING_CUSTOMER_HELP_CENTRE_CLICKED,
)

export const existingCustomerLiveChatClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.EXISTING_CUSTOMER_LIVE_CHAT_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const declinedUserHelpCentreClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.DECLINED_USER_HELP_CENTRE_CLICKED,
)

export const declinedUserLiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.DECLINED_USER_LIVE_CHAT_CLICKED,
)

export const existingCustomerContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.EXISTING_CUSTOMER_CONTACT_FIG_CLICKED,
)

export const declinedUserContactFigClicked = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.DECLINED_USER_CONTACT_FIG_CLICKED, {
    object: actionText,
    action: 'Clicked',
    page_name: screen,
  })
}

export const purposeOfLoanBackClicked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.PURPOSE_OF_LOAN_BACK_CLICKED, {
    object: 'Back',
    action: 'Clicked',
    page_name: screen,
  })
}

export const tosDeclinedTryAgainClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.TOS_DECLINED_TRY_AGAIN_CLICKED,
)

export const tosDeclinedLiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.TOS_DECLINED_LIVE_CHAT_CLICKED,
)

export const tosDeclinedContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.TOS_DECLINED_CONTACT_FIG_CLICKED,
)

export const onfidoEventPosted = getAnalyticsCallback(SEGMENT_CONSTANTS.ONFIDO_EVENT_POSTED)

export const onfidoUserExit = getAnalyticsCallback(SEGMENT_CONSTANTS.ONFIDO_USER_EXIT)

export const onfidoPollingOnfido = getAnalyticsCallback(SEGMENT_CONSTANTS.ONFIDO_POLLING_ONFIDO)

export const errorTryAgainClicked = getAnalyticsCallback(SEGMENT_CONSTANTS.ERROR_TRY_AGAIN_CLICKED)

export const errorLiveChatClicked = getAnalyticsCallback(SEGMENT_CONSTANTS.ERROR_LIVE_CHAT_CLICKED)

export const errorContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.ERROR_CONTACT_FIG_CLICKED,
)

export const errorPageViewedViaErrorBoundary = (actionText: string, screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.ERROR_VIEWED_VIA_ERROR_BOUNDARY, {
    object: actionText,
    action: 'Viewed',
    page_name: screen,
  })
}

export const pageNotFoundTryAgainClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.PAGE_NOT_FOUND_TRY_AGAIN_CLICKED,
)

export const pageNotFoundLiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.PAGE_NOT_FOUND_LIVE_CHAT_CLICKED,
)

export const pageNotFoundContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.PAGE_NOT_FOUND_CONTACT_FIG_CLICKED,
)

export const noAppIdHelpCentreClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.NO_APP_ID_HELP_CENTRE_CLICKED,
)

export const noAppIdContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.NO_APP_ID_CONTACT_FIG_CLICKED,
)

interface PurposeProvidedPropertyType {
  object: string
  loan_purpose: string
  page_name: string
  action: string
  loan_purpose_other?: string
}

export const purposeProvided = (
  loan_purpose: string,
  loan_purpose_other: string,
  screen: StepsEnum,
) => {
  const segmentProperties: PurposeProvidedPropertyType = {
    object: 'Continue',
    loan_purpose: loan_purpose,
    page_name: screen,
    action: 'Clicked',
  }

  if (loan_purpose_other) {
    segmentProperties['loan_purpose_other'] = loan_purpose_other
  }
  segmentAnalytics.track(SEGMENT_CONSTANTS.PURPOSE_PROVIDED, segmentProperties)
}

export const occupationBackClicked = (screen: StepsEnum) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.OCCUPATION_BACK_CLICKED, {
    object: 'Back',
    action: 'Clicked',
    page_name: screen,
  })
}

export const occupationProvided = (
  occupationData: {
    occupation: string
    employment_status: string
    employer: string
    length_of_employment: string
    source_of_income: string
  },
  screen: StepsEnum,
) => {
  segmentAnalytics.track(SEGMENT_CONSTANTS.OCCUPATION_PROVIDED, {
    object: 'Continue',
    page_name: screen,
    action: 'Clicked',
    ...occupationData,
  })
}

export const orderDeclinedHelpCentreClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.ORDER_DECLINED_HELP_CENTRE_CLICKED,
)

export const orderDeclinedLiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.ORDER_DECLINED_LIVE_CHAT_CLICKED,
)

export const orderDeclinedContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.ORDER_DECLINED_CONTACT_FIG_CLICKED,
)

export const qcConsentsBiometricConsentClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.QC_CONSENTS_BIOMETRIC_CONSENT_CLICKED,
)

export const qcConsentsViewMoreClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.QC_CONSENTS_VIEW_MORE_CLICKED,
)

export const kycRetryGenericClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.KYC_RETRY_GENERIC_CLICKED,
)

export const kycRetryImageQualityClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.KYC_RETRY_IMAGE_QUALITY_CLICKED,
)

export const kycRetryPIIClicked = getAnalyticsCallback(SEGMENT_CONSTANTS.KYC_RETRY_PII_CLICKED)

export const kycApplicationDeclined = getAnalyticsCallback(
  SEGMENT_CONSTANTS.KYC_LOADING_PAGE_DECLINED,
)

export const loanPurposeInvestmentScamArticleLinkClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.PURPOSE_OF_LOAN_INVESTMENT_SCAM_ARTICLE_LINK_CLICKED,
)

export const landingPageCTAClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.LANDING_PAGE_CTA_CLICKED,
)

export const secondLookOfferPageCTAClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.LANDING_PAGE_CTA_CLICKED,
)
export const walmartLandingPageCTAClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.WALMART_LANDING_PAGE_CTA_CLICKED,
)

export const kycRetryPIILiveChatClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.KYC_RETRY_PII_LIVE_CHAT_CLICKED,
)

export const kycRetryPIILContactFigClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.KYC_RETRY_PII_CONTACT_FIG_CLICKED,
)

export const paymentProtectionAcceptClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.PAYMENT_PROTECTION_ACCEPT_CLICKED,
)

export const paymentProtectionDeclineClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.PAYMENT_PROTECTION_DECLINE_CLICKED,
)
export const creditRenewalsEligibilityPageLoaded = getAnalyticsCallback(
  SEGMENT_CONSTANTS.CREDIT_RENEWALS_ELIGIBILITY_PAGE_LOADED,
)
export const creditRenewalsEligibilityPageCTAClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.CREDIT_RENEWALS_ELIGIBILITY_PAGE_CTA_CLICKED,
)
export const creditRenewalsApplicationDeclinedPageLoaded = getAnalyticsCallback(
  SEGMENT_CONSTANTS.CREDIT_RENEWALS_DECLINED_PAGE_LOADED,
)

export const creditRenewalsConfirmInformationPageLoaded = getAnalyticsCallback(
  SEGMENT_CONSTANTS.CREDIT_RENEWALS_CONFIRM_INFORMATION_PAGE_LOADED,
)
export const creditRenewalsConfirmInformationPageCTAClicked = getAnalyticsCallback(
  SEGMENT_CONSTANTS.CREDIT_RENEWALS_CONFIRM_INFORMATION_PAGE_CTA_CLICKED,
)
