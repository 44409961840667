import { Box, Fade, List, ListItem, ListItemButton, Radio } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'
import { BackButton, PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { useApi, useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { useScheduleHelper } from 'src/hooks/useScheduleHelper'
import { OfferItemType, StepsEnum } from 'src/types'
import { generateOffers } from 'src/utils/amortization'
import {
  selectOfferBackClicked,
  selectOfferChanged,
  selectOfferConfirmed,
} from 'src/utils/analytics'
import { isQCResident } from 'src/utils/borrower'

import { NoEarlyRepayFeesMessage } from '../common/NoEarlyRepayFeesMessage'

const SelectOfferListItem = styled(ListItem)(({ theme }) => ({
  borderColor: theme.color.grey3,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '3px',
  ['&.Mui-selected']: {
    borderColor: theme.color.brand1,
    borderWidth: '2px',
    background: 'none',
  },
  ':hover': {
    borderColor: theme.color.brand1,
    background: 'none',
  },
}))

const SelectOfferListItemButton = styled(ListItemButton)({
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '0px',
  paddingRight: '0px',
  justifyContent: 'left',
  ':hover': {
    background: 'none',
  },
})

export interface SelectOfferProps {
  nextStep: StepsEnum
}

export default SelectOffer
/**
 *
 */
function SelectOffer({ nextStep }: SelectOfferProps) {
  const {
    setStep,
    offerList,
    cachedApplication,
    setOfferList,
    updateCachedApplication,
    bootstrapInfo,
    updateAsyncRequestStatusCallback,
    setGenericErrorPageError,
  } = useOriginationContext()

  const { saveOfferTerm } = useApi()
  const { generateAndSaveSchedule } = useScheduleHelper()

  const { mutateAsync: saveOfferTermMutation } = useMutation(saveOfferTerm, {
    onSuccess: ({ data }) => {
      updateCachedApplication({
        selected_offer_id: data?.data?.offer_id,
        saved_repayment_schedule: null,
      })
      updateAsyncRequestStatusCallback(
        'getRepaymentSchedule',
        () => generateAndSaveSchedule(), // initialize payment schedule
      )
    },
    ...getRetryQueryOptionsWithErrorStep({
      queryName: 'saveOfferTerm',
      setGenericErrorPageError,
      setStep,
    }),
  })

  const changeOffer = (offer: OfferItemType) => {
    updateCachedApplication({
      selected_offer_term: offer.term_length,
    })
  }

  const { currencyFormat, percentFormat } = useLocalizedFormatters()
  const { t } = useTranslation()

  const apr = bootstrapInfo!.application!.apr

  useEffect(() => {
    const offers = generateOffers(selectedLoanAmount, apr)
    setOfferList(offers)
  }, [cachedApplication.selected_loan_amount])

  useEffect(() => {
    if (!offerList.length || cachedApplication.selected_offer_term) {
      return
    }
    changeOffer(offerList[0])
  }, [offerList])

  const { borrower } = bootstrapInfo
  const selectedLoanAmount = cachedApplication.selected_loan_amount as number
  const selectedOfferTerm = cachedApplication.selected_offer_term as number

  const setSelectedOfferIdOnClick = (offer: OfferItemType) => {
    changeOffer(offer)
    selectOfferChanged(offer.term_length.toString())
  }

  const offerItems = offerList.map(offer => (
    <List key={offer.term_length}>
      <SelectOfferListItem
        selected={offer.term_length === selectedOfferTerm}
        onClick={() => setSelectedOfferIdOnClick(offer)}
        data-testid="offer"
      >
        <SelectOfferListItemButton disableRipple>
          <Radio checked={offer.term_length === selectedOfferTerm} />
          <Box>
            <Typography variant="label">
              {t('SelectOffer.offerAmount', {
                offerAmount: currencyFormat(offer.payment_amount, 2),
              })}
            </Typography>
            <Typography variant="body2">
              {t(isQCResident(borrower) ? 'SelectOffer.aprQC' : 'SelectOffer.apr', {
                apr: percentFormat(apr),
              })}
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <Typography variant="body2" textAlign="right">
              {t('SelectOffer.termLength', { termLength: offer.term_length })}
            </Typography>
          </Box>
        </SelectOfferListItemButton>
      </SelectOfferListItem>
    </List>
  ))

  const confirmOffer = (): void => {
    selectOfferConfirmed(StepsEnum.SELECT_OFFER)
    updateAsyncRequestStatusCallback('saveOffer', () =>
      saveOfferTermMutation({
        loan_amount: selectedLoanAmount,
        term: selectedOfferTerm,
        estimated_due_amount: offerList.filter(o => o.term_length === selectedOfferTerm)[0]
          ?.payment_amount,
      }),
    )
    setStep(nextStep)
  }

  return (
    <DivRoot>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="center">
            {t('SelectOffer.title')}
          </Typography>
        </Grid>
        <Fade>
          <>
            <Grid item xs={12} marginTop="32px">
              {offerItems}
            </Grid>
            <Grid item xs={12} marginTop="8px">
              <NoEarlyRepayFeesMessage />
            </Grid>
            <Grid item xs={12} marginTop="32px">
              <PrimaryCTA
                buttonText={t('common.continueLabel')}
                onClick={() => confirmOffer()}
                disabled={!selectedOfferTerm}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
              <BackButton
                onClick={() => {
                  selectOfferBackClicked(StepsEnum.SELECT_OFFER)
                  setStep(StepsEnum.LOAN_AMOUNT)
                }}
              />
            </Grid>
          </>
        </Fade>
      </Grid>
    </DivRoot>
  )
}
