import { Trans, useTranslation } from 'react-i18next'

import { useDeclined } from 'src/components/CreditRenewal/hooks/useDeclined'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { ContactFigLink, LiveChatLink } from 'src/components/Error/shared/PleaseReachOut'
import { PrimaryCTA } from 'src/components/common/Buttons'
import HelpLinkGeneric from 'src/components/common/HelpLinkGeneric'
import { verificationFailImg } from 'src/images'
import {
  declinedUserHelpCentreClicked,
  existingCustomerContactFigClicked,
  existingCustomerLiveChatClicked,
} from 'src/utils'

export default function CreditRenewalDeclined() {
  const { reason, screen } = useDeclined()
  const { t } = useTranslation()
  const content =
    reason === 'PAYMENT_PENDING' ? (
      <Trans
        i18nKey="CreditRenewals.Declined.paymentPendingContent"
        components={{
          figLiveChatLink: (
            <LiveChatLink screen={screen} analyticsCallback={existingCustomerLiveChatClicked} />
          ),
          emailFigSupportLink: (
            <ContactFigLink screen={screen} analyticsCallback={existingCustomerContactFigClicked} />
          ),
        }}
      />
    ) : (
      <Trans
        i18nKey="CreditRenewals.Declined.declinedContent"
        components={{ helpCentreLink: <HelpLinkGeneric /> }}
      />
    )
  const helpCentreButton = (
    <PrimaryCTA
      target="_blank"
      href={t('HelpDesk.declinedUrl')}
      onClick={() => {
        declinedUserHelpCentreClicked(t('common.helpLabel'), screen)
      }}
      buttonText={t('common.helpLabel')}
    />
  )
  return (
    <ErrorPage
      image={{ src: verificationFailImg, alt: t('ApplicationDeclined.alt') }}
      title={t('CreditRenewals.Declined.title')}
      content={content}
      buttons={[helpCentreButton]}
    />
  )
}
