import { Grid } from '@mui/material'

import { WalmartLandingWrapper } from 'src/components/EndToEnd/landing/Walmart/components/WalmartLandingWrapper'
import { Banner } from 'src/components/EndToEnd/landing/Walmart/sections/Banner'
import { CardSection } from 'src/components/EndToEnd/landing/Walmart/sections/CardSection'
import { FAQSection } from 'src/components/EndToEnd/landing/Walmart/sections/FAQSection'
import { Header } from 'src/components/EndToEnd/landing/Walmart/sections/Header'
import { HowSection } from 'src/components/EndToEnd/landing/Walmart/sections/HowSection'
import { OfferSection } from 'src/components/EndToEnd/landing/Walmart/sections/OfferSection'
import { TaglineSection } from 'src/components/EndToEnd/landing/Walmart/sections/TaglineSection'
import { TermsSection } from 'src/components/EndToEnd/landing/Walmart/sections/TermsSection'
import { TrustpilotSection } from 'src/components/EndToEnd/landing/Walmart/sections/TrustpilotSection'
import { WhySection } from 'src/components/EndToEnd/landing/Walmart/sections/WhySection'

export const WalmartLanding = () => {
  return (
    <WalmartLandingWrapper>
      <Header />
      <Banner />
      <Grid container sx={{ maxWidth: '1200px' }}>
        <OfferSection />
        <TaglineSection />
        <WhySection />
        <CardSection />
        <HowSection />
      </Grid>
      <TrustpilotSection />
      <Grid container sx={{ maxWidth: '1200px' }}>
        <FAQSection />
        <TermsSection />
      </Grid>
    </WalmartLandingWrapper>
  )
}
