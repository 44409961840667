import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ChangeLink, RightBox, TableDivider } from 'src/components/ReviewOrder/styles'
import { PaymentProtectionPlanContainerType } from 'src/components/ReviewOrder/types'
import { useOriginationContext } from 'src/hooks'
import { shieldCheckMarkOutlinedIcon } from 'src/images'
import theme from 'src/themes'
import { StepsEnum } from 'src/types'
import { changePaymentProtectionPlanClicked } from 'src/utils'

export function PaymentProtectionPlanContainer({ isEnrolled }: PaymentProtectionPlanContainerType) {
  const { t } = useTranslation()
  const { setStep } = useOriginationContext()

  return (
    <Grid container>
      <Grid item xs={12} marginBottom="8px">
        <Typography variant="label" marginBottom="0px">
          {t('ReviewOrder.paymentProtectionPlan.title')}
        </Typography>
      </Grid>
      <Grid item container xs={9} alignItems="center">
        {isEnrolled && (
          <img
            style={{ marginRight: theme.spacing(1) }}
            width={20}
            height={20}
            src={shieldCheckMarkOutlinedIcon}
            alt={t('ReviewOrder.paymentProtectionPlan.title')}
          />
        )}
        <Typography variant="body2" color={theme.color.grey6}>
          {isEnrolled
            ? t('ReviewOrder.paymentProtectionPlan.textEnabled')
            : t('ReviewOrder.paymentProtectionPlan.textDisabled')}
        </Typography>
      </Grid>
      <Grid item xs={3} alignItems="center">
        <RightBox>
          <ChangeLink
            onClick={() => {
              setStep(StepsEnum.PAYMENT_PROTECTION)
              changePaymentProtectionPlanClicked(t('ReviewOrder.change'), StepsEnum.ORDER_REVIEW)
            }}
          >
            {t('ReviewOrder.change')}
          </ChangeLink>
        </RightBox>
      </Grid>
      <Grid item xs={12}>
        <TableDivider style={{ marginBottom: 0 }} />
      </Grid>
    </Grid>
  )
}
