import { ReactNode } from 'react'

import { StepsEnum } from 'src/types'

export interface DropDownContainerProps {
  title: ReactNode
  content: ReactNode
  setStep: (step: StepsEnum) => void
}

export interface PaymentContainerType {
  paymentMethod: string
}

export interface PaymentProtectionPlanContainerType {
  isEnrolled: boolean
}

export interface ReviewOrderProps {
  previousStep: StepsEnum
  nextStep: StepsEnum
}

export interface LoanDetailsTextProps {
  isQuebecResident: boolean
  loanAmount: string
  loanTerm: number
  loanApr: string
}

export interface LoanDetailsContainerProps {
  isQuebecResident: boolean
  selectedLoanAmount: number
  selectedTerm: number
  selectedApr: number
}

export interface LoanDetailsSummaryProps {
  selectedLoanAmount: string
  formattedTotalAmount: string
  formattedInterestAmount: string
  isQuebecResident: boolean
}

export interface PaymentBreakdownProps {
  loanPaymentAmount: string
  premiumAmount: string
  premiumTaxAmount: string
  totalAmount: string
}

export enum AgreementTypeEnum {
  TOC = 'accept_toc',
  PAD = 'accept_pad',
  FICO = 'check_fico',
  EMAIL = 'consent_email',
}
