import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SectionContainer } from 'src/components/EndToEnd/landing/Walmart/components/SectionContainer'
import { useConfiguration } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'

export const TaglineSection = () => {
  const { t } = useTranslation()
  const { loanLimits } = useConfiguration()
  const { currencyFormat } = useLocalizedFormatters()

  return (
    <SectionContainer sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Grid item>
        <Typography variant="heading" align="center">
          {t('e2e.Landing.walmart.taglineSection.title')}
        </Typography>
        <Typography variant="body" align="center">
          {t('e2e.Landing.walmart.taglineSection.content', {
            min_amt: currencyFormat(loanLimits?.minLoanAmount, 0),
            max_amt: currencyFormat(loanLimits?.maxLoanAmount, 0),
          })}
        </Typography>
      </Grid>
    </SectionContainer>
  )
}
