import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ApplyNowButton } from 'src/components/EndToEnd/landing/Walmart/components/ApplyNowButton'
import { RateDisclaimer } from 'src/components/EndToEnd/landing/Walmart/components/RateDisclaimer'
import { SectionContainer } from 'src/components/EndToEnd/landing/Walmart/components/SectionContainer'
import { BannerImage, MobileDivider } from 'src/components/EndToEnd/landing/Walmart/styles'
import {
  ApplyNowButtonSizes,
  WalmartLandingPageSections,
} from 'src/components/EndToEnd/landing/Walmart/types'
import { whySectionBanner } from 'src/images'

export const WhySection = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionContainer>
      <Grid
        item
        md={6}
        xs={12}
        sx={{
          order: { xs: 2, md: 1 },
          textAlign: isMobile ? 'center' : 'left',
          paddingRight: isMobile ? 0 : '90px',
          paddingTop: isMobile ? '24px' : 0,
        }}
      >
        <Typography variant="sectionHeading">
          {t('e2e.Landing.walmart.whySection.title')}
        </Typography>
        <Typography variant="body">{t('e2e.Landing.walmart.whySection.content')}</Typography>
        <ApplyNowButton
          size={ApplyNowButtonSizes.SMALL}
          sectionName={WalmartLandingPageSections.WhySection}
        />
        <Box>
          <RateDisclaimer />
        </Box>
        <MobileDivider />
      </Grid>
      <Grid item md={6} xs={12} sx={{ order: { xs: 1, md: 2 } }}>
        <BannerImage src={whySectionBanner} />
      </Grid>
    </SectionContainer>
  )
}
