/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import enTransalation from 'src/locales/en/translation'
import frTransalation from 'src/locales/fr/translation'
import { IS_PRODUCTION, ENVIRONMENT, EnvEnum } from 'src/utils/constants'
export const defaultNS = 'translation'
export const resources = {
  en: {
    translation: enTransalation,
  },
  fr: {
    translation: frTransalation,
  },
} as const
export type SupportedLanguage = keyof typeof resources
export const supportedLanguages = Object.keys(resources) as SupportedLanguage[]

export const EnglishLanguageKey: SupportedLanguage = 'en'
export const FrenchLanguageKey: SupportedLanguage = 'fr'

i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: !(IS_PRODUCTION || ENVIRONMENT === EnvEnum.testing),
    fallbackLng: 'en',
    nonExplicitSupportedLngs: true,
    interpolation: { escapeValue: false },
    supportedLngs: supportedLanguages,
    defaultNS,
    resources,
  })

export default i18n
