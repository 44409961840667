import { useTranslation, Trans } from 'react-i18next'

import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { loanExistsImg } from 'src/images'
import {
  existingCustomerContactFigClicked,
  existingCustomerHelpCentreClicked,
  existingCustomerLiveChatClicked,
  existingCustomerLoginClicked,
} from 'src/utils'
import { StepsEnum } from 'src/types'
import { Q2_PORTAL_URL } from 'src/utils/constants'
import { PrimaryCTA, SecondaryCTA } from 'src/components/common/Buttons'
import ErrorPage from 'src/components/Error/shared/ErrorPage'

export default ApplicationAlreadySettled
/**
 *
 */
function ApplicationAlreadySettled() {
  const { t } = useTranslation()
  const screen = StepsEnum.SETTLED

  const content = (
    <Trans i18nKey="ApplicationAlreadySettled.content">
      Visit your Fig account for details of your loan. Got questions? Visit our help centre to get
      the answers you need. For other concerns{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={existingCustomerLiveChatClicked}
        contactFigAnalytics={existingCustomerContactFigClicked}
      />
    </Trans>
  )
  const loginButton = (
    <PrimaryCTA
      href={Q2_PORTAL_URL}
      onClick={() => {
        existingCustomerLoginClicked(t('ApplicationAlreadySettled.loginLabel'), screen)
      }}
      buttonText={t('ApplicationAlreadySettled.loginLabel')}
    />
  )
  const helpCentreButton = (
    <SecondaryCTA
      href={t('HelpDesk.errorUrl')}
      target="_blank"
      onClick={() => {
        existingCustomerHelpCentreClicked(t('common.helpLabel'), screen)
      }}
      buttonText={t('common.helpLabel')}
    />
  )
  return (
    <ErrorPage
      image={{ src: loanExistsImg, alt: t('ApplicationAlreadySettled.alt') }}
      title={t('ApplicationAlreadySettled.title')}
      content={content}
      buttons={[loginButton, helpCentreButton]}
    />
  )
}
