import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FigDropdown, FigMenuItem } from 'src/components/common/FigDropdown'
import { EmploymentStatus } from 'src/types'

interface OccupationStatusInputProps {
  employmentStatus: EmploymentStatus
  handleSetEmploymentStatus: (status: EmploymentStatus) => void
  getEmploymentStatus: (status: EmploymentStatus) => string
  employmentStatuses: any
}

export const OccupationStatusInput = (props: OccupationStatusInputProps) => {
  const { employmentStatus, handleSetEmploymentStatus, getEmploymentStatus, employmentStatuses } =
    props
  const { t } = useTranslation()
  return (
    <Grid item xs={12} marginBottom="16px" data-testid="confirm-employment-status-input">
      <FigDropdown
        placeholder={t('Occupation.employmentStatus.placeholder')}
        onChange={e => handleSetEmploymentStatus(e.target.value as EmploymentStatus)}
        value={employmentStatus}
        label={t('Occupation.employmentStatus.label')}
        menuItems={employmentStatuses.map((status: EmploymentStatus) => (
          <FigMenuItem key={status} value={status}>
            {getEmploymentStatus(status)}
          </FigMenuItem>
        ))}
      />
    </Grid>
  )
}
