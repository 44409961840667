import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

import { ImgStyle } from 'src/components/EndToEnd/landing/Walmart/styles'

export interface CardProps {
  imgSrc: string
  title: string
  content: string
  [key: string]: any
}

export const CardItem = ({ imgSrc, title, content }: CardProps) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return (
    <Grid
      item
      md={3.75}
      xs={12}
      mx={isTablet ? '48px' : 0}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <ImgStyle
        src={imgSrc}
        sx={{
          width: '80px',
          marginBottom: '20px',
          borderTopLeftRadius: '2%',
          borderTopRightRadius: '2%',
        }}
      />
      <Box ml="16px" pr="16px">
        <Typography variant="subheading" textAlign="center">
          {title}
        </Typography>
        <Typography variant="body" textAlign="center">
          {content}
        </Typography>
      </Box>
    </Grid>
  )
}
