import { ConsentType } from 'src/types'

export enum EnvEnum {
  production = 'production',
  staging = 'staging',
  development = 'development',
  preprod = 'preprod',
  testing = 'testing',
  local = 'local',
  preview = 'preview',
}
export const ENVIRONMENT: EnvEnum = (process.env.REACT_APP_ENVIRONMENT as EnvEnum) ?? EnvEnum.local

export const IS_PRODUCTION = ENVIRONMENT === 'production'

export const API_URL = IS_PRODUCTION
  ? `${process.env.REACT_APP_PUBLIC_URL}/`
  : `${process.env.REACT_APP_API_URL}/`

export const FLINKS_IFRAME_SRC = process.env.REACT_APP_FLINKS_IFRAME_BASE_URL as string
export const LOAN_AMOUNT_STEP = 500
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE
export const FRESHCHAT_TOKEN = '2af4563a-4787-4613-9a3f-63341af13559'
export const FRESHCHAT_HOST = 'https://fig-help.freshchat.com'
export const FRESHCHAT_WIDGET_UUID = '158975d8-8dcb-48c8-8969-0ad2c20d54c3'
export const FRESHCHAT_CHANNEL_NAME = 'Chat With Us!'
export const FRESHCHAT_SCRIPT_URL = 'https://fig-help.freshchat.com/js/widget.js'
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_ORIGINATION_FRONTEND_SEGMENT_WRITE_KEY
export const IS_PREPROD_OR_PROD = IS_PRODUCTION || ENVIRONMENT === 'preprod'
export const LAUNCH_DARKLY_PRODUCTION_CLIENT_ID = '64778f30290162133f7df0ce'
export const LAUNCH_DARKLY_STAGING_CLIENT_ID = '64778f30290162133f7df0cd'
export const LAUNCH_DARKLY_DEVELOPMENT_CLIENT_ID = '64e8d2486f9bd0126b45c7d5'
export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY

const LAUNCH_DARKLY_CLIENT_ID_ENV_MAPPING: Record<keyof typeof EnvEnum, string> = {
  local: LAUNCH_DARKLY_DEVELOPMENT_CLIENT_ID,
  testing: LAUNCH_DARKLY_DEVELOPMENT_CLIENT_ID,
  development: LAUNCH_DARKLY_DEVELOPMENT_CLIENT_ID,
  preview: LAUNCH_DARKLY_DEVELOPMENT_CLIENT_ID,
  staging: LAUNCH_DARKLY_STAGING_CLIENT_ID,
  preprod: LAUNCH_DARKLY_STAGING_CLIENT_ID,
  production: LAUNCH_DARKLY_PRODUCTION_CLIENT_ID,
}

export const LAUNCH_DARKLY_CLIENT_ID =
  LAUNCH_DARKLY_CLIENT_ID_ENV_MAPPING[ENVIRONMENT as EnvEnum] ?? LAUNCH_DARKLY_DEVELOPMENT_CLIENT_ID
export const EXPIRY_DETAIL = 'The authorization token is expired'
export const PRE_AUTH_INVALID_STATUS_DETAIL = 'Pre-authorize check: invalid application status'
export const Q2_PORTAL_URL = process.env.REACT_APP_Q2_PORTAL_URL

export const TMX_ORG_ID = process.env.REACT_APP_TMX_ORG_ID
export const MIN_DAYS_BEFORE_PAYMENT = 7
export const NAME_REGEX = "^[a-zA-Z.'\\- ]+$"
export const MAX_DAYS_BEFORE_PAYMENT = 38
export const MAX_DAYS_BEFORE_PAYMENT_QC = 35
export const CANADIAN_PHONE_NUMBER_LENGTH = 10
export default {
  ENVIRONMENT,
  API_URL,
  FLINKS_IFRAME_SRC,
  LOAN_AMOUNT_STEP,
  SENTRY_DSN,
  SENTRY_RELEASE,
  FRESHCHAT_TOKEN,
  FRESHCHAT_HOST,
  FRESHCHAT_WIDGET_UUID,
  FRESHCHAT_CHANNEL_NAME,
  FRESHCHAT_SCRIPT_URL,
  SEGMENT_WRITE_KEY,
  IS_PRODUCTION,
  EXPIRY_DETAIL,
  PRE_AUTH_INVALID_STATUS_DETAIL,
  TMX_ORG_ID,
  MIN_DAYS_BEFORE_PAYMENT,
}

export const ALTERNA_FLINKS_IFRAME_SRC = process.env
  .REACT_APP_FLINKS_ALTERNA_IFRAME_BASE_URL as string

export const GOOGLE_AUTOCOMPLETE_CONFIG = {
  autocompleteTypes: [],
  componentRestrictions: {
    country: ['CA'],
  },
}

export const DEFAULT_CONSENTS: ConsentType = {
  accept_pad: false,
  accept_toc: false,
  accept_policy: false,
  check_fico: false,
  consent_email: false,
}

export const SENTRY_TAGS = {
  partnerId: 'partner_id',
}

export const CSS_CLASS_NAMES = {
  PREQUAL_BUTTON_CLICK_CLASS: 'prequalButton',
}
