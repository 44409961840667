import {
  ApplicationStatus,
  DeclineCodes,
  BankingType,
  OfferItemType,
  PrequalResponseDataType,
  RepaymentScheduleResponseType,
  PIIMismatchReasonsEnum,
  BootstrapInfoType,
} from 'src/types'

export const APPLICATION_ID = 'a8uDN00000000Bi'
export const JWT = 'eyJDHSDHSDHDSJCXNCmockJWT'

export const orderData = [
  {
    uid: 1,
    image: require('./speaker.png'),
    description: 'Bose Smart Soundbar 900 with Dolby Atmos & Bos...',
    value: '$0,000.00',
  },
  {
    uid: 2,
    image: require('./speaker.png'),
    description: 'Bose Smart Soundbar 900 with Dolby Atmos & Bos...',
    value: '$0,000.00',
  },
]

export const repaymentScheduleData = {
  isLoading: false,
}

export const offerData: OfferItemType[] = [
  {
    term_length: 99,
    payment_amount: 80.09,
  },
  {
    term_length: 72,
    payment_amount: 111.82,
  },
  {
    term_length: 48,
    payment_amount: 406.56,
  },
  {
    term_length: 36,
    payment_amount: 607.12,
  },
]

export const repaymentScheduleMonthlyOther: RepaymentScheduleResponseType = {
  isLoading: false,
  withdraw_amount: '380.31',
  total_estimated_interest: 7818.23,
  first_payment_date: '2021-08-06',
  payment_cycle_due_date: '2021-08-06',
  monthly_choice: 'OTHER',
  schedule: 'ONCE_A_MONTH',
}

export const repaymentScheduleMonthlyFirst: RepaymentScheduleResponseType = {
  isLoading: false,
  withdraw_amount: '380.31',
  total_estimated_interest: 7818.23,
  first_payment_date: '2021-06-01',
  payment_cycle_due_date: '2021-06-01',
  monthly_choice: 'FIRST',
  schedule: 'ONCE_A_MONTH',
}

export const repaymentScheduleMonthlyLast: RepaymentScheduleResponseType = {
  isLoading: false,
  withdraw_amount: '380.31',
  total_estimated_interest: 7818.23,
  first_payment_date: '2021-08-31',
  payment_cycle_due_date: '2021-08-31',
  monthly_choice: 'LAST',
  schedule: 'ONCE_A_MONTH',
}

export const repaymentScheduleMonthlyFifteenth: RepaymentScheduleResponseType = {
  isLoading: false,
  withdraw_amount: '380.31',
  total_estimated_interest: 7818.23,
  first_payment_date: '2021-06-15',
  payment_cycle_due_date: '2021-06-15',
  monthly_choice: 'FIFTEENTH',
  schedule: 'ONCE_A_MONTH',
}

export const repaymentScheduleEveryWeek: RepaymentScheduleResponseType = {
  isLoading: false,
  withdraw_amount: '380.31',
  total_estimated_interest: 7818.23,
  first_payment_date: '2021-08-06',
  payment_cycle_due_date: '2021-08-06',
  schedule: 'EVERY_WEEK',
}

export const repaymentScheduleEveryTwoWeeks: RepaymentScheduleResponseType = {
  isLoading: false,
  withdraw_amount: '380.31',
  total_estimated_interest: 7818.23,
  first_payment_date: '2021-08-18',
  payment_cycle_due_date: '2021-08-18',
  schedule: 'EVERY_TWO_WEEKS',
}

export const repaymentScheduleTwiceAMonth: RepaymentScheduleResponseType = {
  isLoading: false,
  withdraw_amount: '380.31',
  total_estimated_interest: 7818.23,
  first_payment_date: '2021-08-16',
  payment_cycle_due_date: '2021-08-16',
  schedule: 'TWICE_A_MONTH',
}

export const mockBankAccount: BankingType = {
  bank_id: 'bnb32nm249dskjd',
  payment_method: 'EFT',
  account_type: 'Chequing',
  account_number: '***1000',
  institution_number: '777',
  institution_name: 'FlinksCapital',
  transit_number: '1234',
  borrower_id: '002EG00002fG6o8XBD',
  last_used: '2023-07-26T19:41:46Z',
  updated_at: '2023-07-26T19:41:46Z',
  created_at: '2023-07-25T15:45:05Z',
}

export const mockPrequalResponse: PrequalResponseDataType = {
  data: {
    status: ApplicationStatus.PRE_AUTHORIZED,
    application_id: 'a8uDN0000000IonYAE',
    borrower_id: '001DN00000D6C47YAF',
    offers: [
      {
        payment_amount: 681.11,
        payment_frequency: 'MONTHLY',
        term_duration_months: 60,
        offer_id: 'a8oDN0000000ETKYA2',
        apr: 12.9,
        total_cost_of_borrowing: 40866.94,
        loan_amount: 30000,
        fees: 0,
      },
      {
        payment_amount: 803.39,
        payment_frequency: 'MONTHLY',
        term_duration_months: 48,
        offer_id: 'a8oDN0000000ETJYA2',
        apr: 12.9,
        total_cost_of_borrowing: 38562.95,
        loan_amount: 30000,
        fees: 0,
      },
      {
        payment_amount: 1009.45,
        payment_frequency: 'MONTHLY',
        term_duration_months: 36,
        offer_id: 'a8oDN0000000ETIYA2',
        apr: 12.9,
        total_cost_of_borrowing: 36340.37,
        loan_amount: 30000,
        fees: 0,
      },
      {
        payment_amount: 1424.94,
        payment_frequency: 'MONTHLY',
        term_duration_months: 24,
        offer_id: 'a8oDN0000000ETHYA2',
        apr: 12.9,
        total_cost_of_borrowing: 34198.65,
        loan_amount: 30000,
        fees: 0,
      },
    ],
    application_url:
      'https://apply.development.fig.ca/#/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbl9pZCI6ImE4dUROMDAwMDAwMElvbllBRSIsImJvcnJvd2VyX2lkIjoiMDAxRE4wMDAwMEQ2QzQ3WUFGIiwiZXhwIjoxNjk1MDU5NDcwLjB9.zLom7W8q1rZTqKJ29ThdqQ5u5jwgGce3rp2K_7OwEcI/loanamount',
    max_loan_amount: 30000,
    min_loan_amount: 2000,
    expires_at: '2023-09-18T17:51:10Z',
    partner_customer_id: '',
  },
}

const enOccupations = [
  'Accountant - Accounting, Banking, Insurance &amp; Finance',
  'Accounting, Banking, Insurance or Finance support activities - Accounting, Banking, Insurance &amp; Finance',
  'Actuary - Accounting, Banking, Insurance &amp; Finance',
  'Auditor - Accounting, Banking, Insurance &amp; Finance',
  'Bank Administrator - Accounting, Banking, Insurance &amp; Finance',
  'Bank Teller - Accounting, Banking, Insurance &amp; Finance',
  'Bookkeeper - Accounting, Banking, Insurance &amp; Finance',
  'Branch Manager - Accounting, Banking, Insurance &amp; Finance',
  'Chief Financial Officer - Accounting, Banking, Insurance &amp; Finance',
  'Claims Adjustor - Accounting, Banking, Insurance &amp; Finance',
  'Clerical Worker - Accounting, Banking, Insurance &amp; Finance',
  'Collections Officer - Accounting, Banking, Insurance &amp; Finance',
  'Commodities Trader - Accounting, Banking, Insurance &amp; Finance',
  'Compliance/Risk Analyst - Accounting, Banking, Insurance &amp; Finance',
  'Consultant - Accounting, Banking, Insurance &amp; Finance',
  'Controller - Accounting, Banking, Insurance &amp; Finance',
  'Transportation &amp; Ultilities Support Activities - Transportation &amp; Utilities',
  'Transportation Dispatcher - Transportation &amp; Utilities',
  'Transportation Manager / Supervisor - Transportation &amp; Utilities',
  'Truck Driver - Transportation &amp; Utilities',
  'Underground Worker - Transportation &amp; Utilities',
  'Utility Worker - Transportation &amp; Utilities',
  'Well Drillers / Operators - Transportation &amp; Utilities',
  'Well Site Supervisor - Transportation &amp; Utilities',
  'Water Treatment Technician - Transportation &amp; Utilities',
]

const frOccupations = [
  'Relations avec les médias – Arts, spectacles et communications',
  'Modèle – Arts, spectacles et communications',
  'Employé de musée – Arts, spectacles et communications',
  'Musicien – Arts, spectacles et communications',
  'Artiste de spectacle – Arts, spectacles et communications',
  'Photographe – Arts, spectacles et communications',
  'Joueur de poker – Arts, spectacles et communications',
  'Imprimeur – Arts, spectacles et communications',
  'Réalisateur – Arts, spectacles et communications',
  'Employé de production – Arts, spectacles et communications',
  'Relecteur – Arts, spectacles et communications',
  'Agent de relations publiques – Arts, spectacles et communications',
  'Publiciste – Arts, spectacles et communications',
  'Éditeur – Arts, spectacles et communications',
  'Opérateur de circuit de course – Arts, spectacles et communications',
  'Journaliste – Arts, spectacles et communications',
  'Chercheur – Arts, spectacles et communications',
  'Sculpteur – Arts, spectacles et communications',
]

export const saveLanguageCallback = ({
  searchParams: { language },
}: {
  searchParams: { language?: string }
}) => ({
  data: {
    occupations: language === 'fr' ? frOccupations : enOccupations,
  },
})

export const mockSecondLookHomePrequalResponse: PrequalResponseDataType = {
  data: {
    status: ApplicationStatus.DECLINED,
    second_look_url:
      'https://apply.development.fig.ca/#/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbl9pZCI6ImE4dUROMDAwMDAwMElvbllBRSIsImJvcnJvd2VyX2lkIjoiMDAxRE4wMDAwMEQ2QzQ3WUFGIiwiZXhwIjoxNjk1MDU5NDcwLjB9.zLom7W8q1rZTqKJ29ThdqQ5u5jwgGce3rp2K_7OwEcI/loanamount',
    is_homeowner: true,
    is_second_look_approved: true,
    decline_message: {
      affiliate_msg_en: 'test home owner',
      affiliate_msg_fr: 'test home owner fr',
    },
  },
}

export const mockSecondLookNonHomePrequalResponse: PrequalResponseDataType = {
  data: {
    status: ApplicationStatus.DECLINED,
    second_look_url:
      'https://apply.development.fig.ca/#/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbl9pZCI6ImE4dUROMDAwMDAwMElvbllBRSIsImJvcnJvd2VyX2lkIjoiMDAxRE4wMDAwMEQ2QzQ3WUFGIiwiZXhwIjoxNjk1MDU5NDcwLjB9.zLom7W8q1rZTqKJ29ThdqQ5u5jwgGce3rp2K_7OwEcI/loanamount',
    is_homeowner: false,
    is_second_look_approved: true,
    decline_message: {
      affiliate_msg_en: 'test non home owner',
      affiliate_msg_fr: 'test non home owner fr',
    },
  },
}

export const mockErrorExistingEmailPrequalResponse: PrequalResponseDataType = {
  data: {
    status: ApplicationStatus.DECLINED,
    second_look_url: '',
    is_homeowner: false,
    is_second_look_approved: false,
    decline_code: DeclineCodes.EXISTING_EMAIL,
    decline_message: {
      affiliate_msg_en: 'test error existing email',
      affiliate_msg_fr: 'test error existing email fr',
    },
  },
}

export const mockErrorExistingPhonePrequalResponse: PrequalResponseDataType = {
  data: {
    status: ApplicationStatus.DECLINED,
    second_look_url: '',
    is_homeowner: false,
    is_second_look_approved: false,
    decline_code: DeclineCodes.EXISTING_PHONE,
    decline_message: {
      affiliate_msg_en: 'test error existing phone',
      affiliate_msg_fr: 'test error existing phone fr',
    },
  },
}

export const mockErrorExistingEmailPhonePrequalResponse: PrequalResponseDataType = {
  data: {
    status: ApplicationStatus.DECLINED,
    second_look_url: '',
    is_homeowner: false,
    is_second_look_approved: false,
    decline_code: DeclineCodes.EXISTING_EMAIL_PHONE,
    decline_message: {
      affiliate_msg_en: 'test error existing phone and email',
      affiliate_msg_fr: 'test error existing phone and email fr',
    },
  },
}

export const mockPIIAllMismatchResponse: any = {
  data: {
    piiMismatchReason: PIIMismatchReasonsEnum.ALL_MATCH,
    piiMismatchInfo: {
      firstName: 'John Lee',
      lastName: 'Doe',
      dob: '1990-01-01',
    },
  },
}

export const mockPIINameMismatchResponse: any = {
  data: {
    piiMismatchReason: PIIMismatchReasonsEnum.NAME_MATCH,
    piiMismatchInfo: {
      firstName: 'John Lee',
      lastName: 'Doe',
      dob: '1990-01-01',
    },
  },
}

export const mockPIIDobMismatchResponse: any = {
  data: {
    piiMismatchReason: PIIMismatchReasonsEnum.DOB_MATCH,
    piiMismatchInfo: {
      firstName: 'John Lee',
      lastName: 'Doe',
      dob: '1990-01-01',
    },
  },
}

export const bootstrapProtectionApplied: BootstrapInfoType = {
  system_date: '2024-02-02',
  partner: {
    partner_id: '001DP00001mbOfvYAE',
    name: 'Fig Financial',
    dashboard_page_url: '',
    loan_page_url: '',
    logo: {
      fr: 'https://figfinancial--figuat.sandbox.my.site.com/resource/1698326997000/FigFinanceLogoFrench',
      en: 'https://figfinancial--figuat.sandbox.my.site.com/resource/1680527653000/FigFinanceLogo',
    },
  },
  application: {
    payment_protection_applied: true,
    payment_protection_premium_rate: 15,
    payment_protection_tax_rate: 6,
    applicable_for_protection: true,
    application_id: 'a8uG10000006D4DIAU',
    disbursal_day: null,
    status: 'PRE-AUTHORIZED',
    application_is_expired: false,
    min_loan_amount: 2000,
    apr: 13.99,
    accept_right_to_access_consent: false,
    accept_biometric_consent: false,
    accept_pad: false,
    accept_toc: false,
    accept_policy: false,
    check_fico: false,
    consent_email: false,
    max_loan_amount: 30000.0,
  },
  borrower: {
    borrower_id: '001G100000FYZKTIA5',
    borrower_first_name: 'Albert',
    borrower_last_name: 'Thivey',
    borrower_email: 'albert.thivey@example.com',
    borrower_mobile: '7095798181',
    borrower_province: 'NL',
    available_credit: 30000.0,
    language_preference: 'en',
  },
}
