import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { useApi, useOriginationContext } from 'src/hooks'
import {
  EmploymentStatus,
  FigAutocompleteAddress,
  GetApplicantDataResponseType,
  StepsEnum,
} from 'src/types'
import { creditRenewalsConfirmInformationPageLoaded } from 'src/utils'

export const useConfirmInformation = () => {
  const { setStep } = useOriginationContext()
  const { creditRenewalGetApplicantData, creditRenewalUpdateApplicantData } = useApi()
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true)
  const [applicantData, setApplicantData] = useState<GetApplicantDataResponseType>({
    fullName: 'test test',
    dateOfBirth: '1971-12-06',
    phoneNumber: '+1 555-555-5555',
    employmentData: {
      occupation: 'Monk - Religious Vocation',
      occupation_language: 'en',
      employment_status: 'Employed' as EmploymentStatus,
      employer: 'test',
      income_source: 'test',
      employment_start_date: '2021-12-06',
    },
    borrower: {
      borrower_id: '001Au00000Yfu14IAB',
      borrower_first_name: 'Dusty',
      borrower_last_name: 'Dawson',
      borrower_email: 'testnut@yopmail.com',
      borrower_mobile: '6047471998',
      borrower_dob: '1971-12-06',
      available_credit: 3000.0,
      borrower_address_1: '5471 Gillis Rd',
      borrower_address_2: '',
      borrower_province: 'BC',
      borrower_city: 'Quesnel',
      borrower_country: 'CA',
      postal_code: 'V2J6L8',
      language_preference: 'en-ca',
    },
    purposeOfLoan: '',
  })

  const { error, isLoading } = useQuery(
    'creditRenewalGetApplicantData',
    creditRenewalGetApplicantData,
    {
      // enabled: true, //TODO: disabled when testing
      enabled: false,
      useErrorBoundary: false,
      onSuccess(data: GetApplicantDataResponseType) {
        if (data) {
          setApplicantData(data)
        }
      },
    },
  )

  const handlePhoneInput = (inputString: string) => {
    setApplicantData({
      ...applicantData,
      phoneNumber: inputString,
    })
  }

  const handlePurposeOfLoanInput = (inputOption: string) => {
    setApplicantData({
      ...applicantData,
      purposeOfLoan: inputOption,
    })
  }

  const handleOccupationEmploymentStatus = (inputEmploymentStatus: EmploymentStatus) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        employment_status: inputEmploymentStatus,
      },
    })
  }

  const handleOccupationEmployerName = (inputEmployerName: string) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        employer: inputEmployerName,
      },
    })
  }

  const handleOccupationStartDate = (inputStartDate: string) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        employment_start_date: inputStartDate,
      },
    })
  }

  const handleOccupationInput = (inputOccupation: string) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        occupation: inputOccupation,
      },
    })
  }

  const handleOccupationIncomeSource = (inputIncomeSource: string) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        income_source: inputIncomeSource,
      },
    })
  }

  const handleAddressInput = () => (addressObj: FigAutocompleteAddress) => {
    setApplicantData({
      ...applicantData,
      borrower: {
        ...applicantData.borrower,
        borrower_address_1: addressObj.address_1,
        borrower_address_2: addressObj.address_2,
        borrower_province: addressObj.province_code,
        borrower_city: addressObj.city,
        borrower_country: addressObj.country_code,
        postal_code: addressObj.postal_code,
      },
    })
  }

  const handleConfirm = async () => {
    const response = await creditRenewalUpdateApplicantData(applicantData)
    if (response.status === 200) {
      // setStep(StepsEnum.CREDIT_RENEWAL_CONFIRM_INFORMATION) //TODO: MOVE TO THE NEXT STEP (page 3) in the next ticket
    } else {
      setStep(StepsEnum.ERROR)
    }
  }

  useEffect(function trackPageLoadEvent() {
    creditRenewalsConfirmInformationPageLoaded(
      'Confirm Information',
      StepsEnum.CREDIT_RENEWAL_CONFIRM_INFORMATION,
    )
  }, [])

  useEffect(
    function enableConfirmButton() {
      if (applicantData.purposeOfLoan && applicantData.employmentData.occupation) {
        setIsConfirmDisabled(false)
      }
    },
    [applicantData],
  )

  return {
    isLoading,
    error,
    applicantData,
    handlePhoneInput,
    handleConfirm,
    handlePurposeOfLoanInput,
    handleOccupationEmploymentStatus,
    handleOccupationEmployerName,
    handleOccupationStartDate,
    handleOccupationInput,
    handleOccupationIncomeSource,
    handleAddressInput,
    isConfirmDisabled,
  }
}
