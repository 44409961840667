import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { captureMessage } from '@sentry/react'
import { addDays, parseISO } from 'date-fns'
import { Dispatch, useEffect, useReducer, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { BiMonthlyScheduleOptions } from 'src/components/PaymentSchedule/scheduleOptions/BiMonthlyScheduleOptions'
import { EveryTwoWeeksScheduleOptions } from 'src/components/PaymentSchedule/scheduleOptions/EveryTwoWeeksScheduleOptions'
import { EveryWeekScheduleOptions } from 'src/components/PaymentSchedule/scheduleOptions/EveryWeekScheduleOptions'
import { MonthlyScheduleOptions } from 'src/components/PaymentSchedule/scheduleOptions/MonthlyScheduleOptions'
import { PaymentScheduleCalendar } from 'src/components/PaymentSchedule/shared/PaymentScheduleCalendar'
import { RepaymentScheduleList } from 'src/components/PaymentSchedule/shared/RepaymentScheduleList'
import { ButtonCTA, PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import Notification, {
  InformationIconStyled,
  NotificationBold,
} from 'src/components/common/Notification'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useApi, useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { useScheduleHelper } from 'src/hooks/useScheduleHelper'
import { RepaymentScheduleResponseDataType, RepaymentScheduleResponseType } from 'src/types'
import { MonthlyScheduleEnum, PaymentScheduleEnum, StepsEnum } from 'src/types/common'
import { saveScheduleClicked, scheduleCancelClicked } from 'src/utils'
import { isQCResident } from 'src/utils/borrower'
import {
  MAX_DAYS_BEFORE_PAYMENT,
  MAX_DAYS_BEFORE_PAYMENT_QC,
  MIN_DAYS_BEFORE_PAYMENT,
} from 'src/utils/constants'

function DateRolloverNotification({ firstPaymentDate }: { firstPaymentDate: string }) {
  const { dateFormatCustom } = useLocalizedFormatters()
  const { t } = useTranslation()
  const firstPaymentDateFormatted = dateFormatCustom(
    parseISO(firstPaymentDate),
    t('PaymentSchedule.EditPaymentSchedule.rolloverDateFormat'),
  )

  return (
    <Grid item xs={12} marginBottom="24px" marginTop="8px">
      <Notification
        content={
          <Trans
            i18nKey="PaymentSchedule.EditPaymentSchedule.rolloverNotification"
            values={{ firstPaymentDateFormatted }}
          >
            We will process your first payment on{' '}
            <NotificationBold>{firstPaymentDateFormatted}</NotificationBold>, as your previous
            selection falls on a weekend or a holiday.
          </Trans>
        }
        icon={<InformationIconStyled />}
      />
    </Grid>
  )
}

function FrequencyOptions({
  selectedFrequency,
  updateSelectedFrequency,
}: {
  selectedFrequency: PaymentScheduleEnum
  updateSelectedFrequency: (frequency: PaymentScheduleEnum) => void
}) {
  const { t } = useTranslation()
  const frequencyOptions = [
    {
      frequency: PaymentScheduleEnum.EVERY_WEEK,
      primaryText: t('PaymentSchedule.EditPaymentSchedule.frequencyOptions.everyWeek'),
    },
    {
      frequency: PaymentScheduleEnum.EVERY_TWO_WEEKS,
      primaryText: t('PaymentSchedule.EditPaymentSchedule.frequencyOptions.everyTwoWeeks'),
    },
    {
      frequency: PaymentScheduleEnum.TWICE_A_MONTH,
      primaryText: t('PaymentSchedule.EditPaymentSchedule.frequencyOptions.twiceAMonth.primary'),
      secondaryText: t(
        'PaymentSchedule.EditPaymentSchedule.frequencyOptions.twiceAMonth.secondary',
      ),
    },
    {
      frequency: PaymentScheduleEnum.ONCE_A_MONTH,
      primaryText: t('PaymentSchedule.EditPaymentSchedule.frequencyOptions.onceAMonth'),
    },
  ].map(({ frequency, primaryText, secondaryText }) => ({
    onClick: () => updateSelectedFrequency(frequency),
    isSelected: selectedFrequency === frequency,
    primaryText,
    secondaryText,
  }))
  return <RepaymentScheduleList options={frequencyOptions} />
}

function FrequencySubOptions({
  selectedSchedule,
  dispatch,
}: {
  selectedSchedule: ScheduleSelection
  dispatch: Dispatch<ScheduleAction>
}) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedSchedule.selectedFrequency])

  const {
    bootstrapInfo: { system_date: systemDate, borrower },
  } = useOriginationContext()
  const earliestDate = addDays(parseISO(systemDate as string), MIN_DAYS_BEFORE_PAYMENT)
  const monthlyLatestDate = addDays(
    parseISO(systemDate as string),
    isQCResident(borrower) ? MAX_DAYS_BEFORE_PAYMENT_QC : MAX_DAYS_BEFORE_PAYMENT,
  )

  const setScheduleReady = () => dispatch({ type: ScheduleActionType.MADE_SELECTION })

  const setSelectedFirstPaymentDate = (newFirstPaymentDate: Date | null) =>
    dispatch({ type: ScheduleActionType.UPDATE_FIRST_PAYMENT_DATE, newFirstPaymentDate })

  const setSelectedMonthlyChoice = (newMonthlyChoice: MonthlyScheduleEnum) =>
    dispatch({ type: ScheduleActionType.UPDATE_MONTHLY_CHOICE, newMonthlyChoice })

  const { selectedFrequency, selectedMonthlyChoice, selectedFirstPaymentDate } = selectedSchedule

  const getSubOptions = () => {
    switch (selectedFrequency) {
      case PaymentScheduleEnum.EVERY_WEEK:
        return (
          <EveryWeekScheduleOptions
            {...{
              earliestDate,
              selectedFirstPaymentDate,
              setSelectedFirstPaymentDate,
              setScheduleReady,
            }}
          />
        )
      case PaymentScheduleEnum.EVERY_TWO_WEEKS:
        return (
          <EveryTwoWeeksScheduleOptions
            {...{
              earliestDate,
              selectedFirstPaymentDate,
              setSelectedFirstPaymentDate,
              setScheduleReady,
            }}
          />
        )
      case PaymentScheduleEnum.TWICE_A_MONTH:
        return <BiMonthlyScheduleOptions {...{ setScheduleReady }} />
      case PaymentScheduleEnum.ONCE_A_MONTH:
        return (
          <MonthlyScheduleOptions
            {...{
              currentDate: parseISO(systemDate as string),
              earliestDate,
              latestDate: monthlyLatestDate,
              selectedMonthlyChoice,
              setSelectedMonthlyChoice,
              selectedFirstPaymentDate,
              setSelectedFirstPaymentDate,
              setScheduleReady,
            }}
          />
        )
      default:
        captureMessage(`Error unhandled payment frequency`, {
          level: 'warning',
          extra: { selectedFrequency },
        })
        return <></>
    }
  }
  return <div ref={containerRef}>{getSubOptions()}</div>
}

/**
 *
 */
export function RepaymentContinue({
  disabled,
  scheduleState,
}: {
  disabled: boolean
  scheduleState: ScheduleState
}) {
  const { t } = useTranslation()
  const { setStep } = useOriginationContext()
  const { saveSchedule } = useScheduleHelper()
  const saveScheduleLabel = t('PaymentSchedule.EditPaymentSchedule.saveScheduleLabel')
  const cancelScheduleLabel = t('common.cancelLabel')

  const handleContinue = () => {
    const {
      withdraw_amount,
      total_estimated_interest,
      first_payment_date,
      monthly_choice,
      schedule,
      payment_cycle_due_date,
    } = scheduleState.generated!
    saveSchedule({
      withdraw_amount,
      total_estimated_interest: total_estimated_interest.toString(),
      first_payment_date,
      monthly_choice,
      schedule,
      payment_cycle_due_date,
    })
    saveScheduleClicked(
      saveScheduleLabel,
      StepsEnum.REPAYMENT_SCHEDULE,
      scheduleState.generated!.schedule,
    )
    setStep(StepsEnum.REPAYMENT_SCHEDULE_CONFIRM)
  }

  const handleCancel = () => {
    scheduleCancelClicked(cancelScheduleLabel, StepsEnum.REPAYMENT_SCHEDULE)
    setStep(StepsEnum.REPAYMENT_SCHEDULE_CONFIRM)
  }

  return (
    <>
      <Grid item xs={12} display="flex" justifyContent="center" id="continueBtn">
        <PrimaryCTA buttonText={saveScheduleLabel} onClick={handleContinue} disabled={disabled} />
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        marginTop="16px"
        marginBottom="28px"
      >
        <ButtonCTA variant="textGrey" onClick={handleCancel} buttonText={cancelScheduleLabel} />
      </Grid>
    </>
  )
}

export type ScheduleSelection = {
  selectedFrequency: PaymentScheduleEnum
  selectedMonthlyChoice?: MonthlyScheduleEnum
  selectedFirstPaymentDate: Date | null
}

type ScheduleState = {
  madeSelection: boolean
  generatedPaymentSchedule: boolean
  selection: ScheduleSelection
  generated?: RepaymentScheduleResponseType
}

enum ScheduleActionType {
  UPDATE_FREQUENCY,
  UPDATE_MONTHLY_CHOICE,
  UPDATE_FIRST_PAYMENT_DATE,
  MADE_SELECTION,
  GENERATED_PAYMENT_SCHEDULE,
}

type ScheduleAction =
  | { type: ScheduleActionType.UPDATE_FREQUENCY; newFrequency: PaymentScheduleEnum }
  | { type: ScheduleActionType.UPDATE_MONTHLY_CHOICE; newMonthlyChoice: MonthlyScheduleEnum }
  | { type: ScheduleActionType.UPDATE_FIRST_PAYMENT_DATE; newFirstPaymentDate: Date | null }
  | { type: ScheduleActionType.MADE_SELECTION }
  | {
      type: ScheduleActionType.GENERATED_PAYMENT_SCHEDULE
      generated: RepaymentScheduleResponseType
    }

const reducer = (prevState: ScheduleState, action: ScheduleAction): ScheduleState => {
  switch (action.type) {
    case ScheduleActionType.UPDATE_FREQUENCY:
      return {
        madeSelection: false,
        generatedPaymentSchedule: false,
        selection: {
          selectedFrequency: action.newFrequency,
          selectedFirstPaymentDate: null,
        },
      }
    case ScheduleActionType.UPDATE_MONTHLY_CHOICE:
      return {
        madeSelection: false,
        generatedPaymentSchedule: false,
        selection: {
          selectedFrequency: prevState.selection.selectedFrequency,
          selectedMonthlyChoice: action.newMonthlyChoice,
          selectedFirstPaymentDate: null,
        },
      }
    case ScheduleActionType.UPDATE_FIRST_PAYMENT_DATE:
      return {
        madeSelection: false,
        generatedPaymentSchedule: false,
        selection: {
          ...prevState.selection,
          selectedFirstPaymentDate: action.newFirstPaymentDate,
        },
      }
    case ScheduleActionType.MADE_SELECTION:
      return {
        ...prevState,
        madeSelection: true,
        generatedPaymentSchedule: false,
      } // ^ madeSelection: true & generatedPaymentSchedule: false should trigger useEffect
    case ScheduleActionType.GENERATED_PAYMENT_SCHEDULE:
      if (!prevState.madeSelection) {
        // Don't update generatedPaymentSchedule if the selection has changed
        return prevState
      }

      return {
        ...prevState,
        generated: action.generated,
        generatedPaymentSchedule: true,
      }
  }
}

export default EditPaymentSchedule
/**
 *
 */
function EditPaymentSchedule() {
  const { asyncRequestsInProgress } = useOriginationContext()
  const { frequency, monthlyChoice, paymentCycleDueDate } = useScheduleHelper()
  const { t } = useTranslation()
  const { dateFormat } = useLocalizedFormatters()
  const { cachedSchedule } = useScheduleHelper()

  const initialScheduleState: ScheduleState = {
    madeSelection: false,
    generatedPaymentSchedule: false,
    selection: {
      selectedFrequency: frequency ?? PaymentScheduleEnum.ONCE_A_MONTH,
      selectedMonthlyChoice: frequency ? monthlyChoice : MonthlyScheduleEnum.OTHER,
      selectedFirstPaymentDate: paymentCycleDueDate,
    },
  }
  const [scheduleState, dispatch] = useReducer<typeof reducer>(reducer, initialScheduleState)

  const { generateRepaymentSchedule } = useApi()

  const { refetch } = useQuery<RepaymentScheduleResponseDataType>(
    [
      'generatePaymentSchedule',
      scheduleState.selection.selectedFirstPaymentDate,
      scheduleState.selection.selectedMonthlyChoice,
      scheduleState.selection.selectedFirstPaymentDate,
    ],
    () => {
      const { selectedFrequency, selectedMonthlyChoice, selectedFirstPaymentDate } =
        scheduleState.selection!

      return generateRepaymentSchedule({
        schedule: selectedFrequency,
        monthly_choice: selectedMonthlyChoice,
        first_payment_date: dateFormat(selectedFirstPaymentDate),
      })
    },
    {
      enabled: false,
      onSuccess: result => {
        dispatch({
          type: ScheduleActionType.GENERATED_PAYMENT_SCHEDULE,
          generated: result.data,
        })
      },
    },
  )

  useEffect(() => {
    if (scheduleState.madeSelection && !scheduleState.generatedPaymentSchedule) {
      refetch()
    }
  }, [
    scheduleState.selection.selectedFirstPaymentDate,
    scheduleState.selection.selectedMonthlyChoice,
    scheduleState.selection.selectedFirstPaymentDate,
    scheduleState.madeSelection,
    scheduleState.generatedPaymentSchedule,
  ])

  if (asyncRequestsInProgress.saveOffer) {
    return <LoadingAnimation />
  }

  const isFirstPaymentDateMismatch =
    scheduleState.generatedPaymentSchedule &&
    scheduleState.generated?.first_payment_date !== scheduleState.generated?.payment_cycle_due_date

  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12} paddingBottom="8px" marginBottom="8px">
          <Typography variant="h2" textAlign="center">
            {t('PaymentSchedule.EditPaymentSchedule.title')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body2" color="black">
            {t('PaymentSchedule.EditPaymentSchedule.frequencyTitle')}
          </Typography>
        </Grid>
        <Grid container spacing="0px">
          <Grid item xs={12}>
            <FrequencyOptions
              selectedFrequency={scheduleState.selection.selectedFrequency}
              updateSelectedFrequency={newFrequency =>
                dispatch({ type: ScheduleActionType.UPDATE_FREQUENCY, newFrequency })
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing="0px">
          <Grid item xs={12}>
            {<FrequencySubOptions selectedSchedule={scheduleState.selection} dispatch={dispatch} />}
            {isFirstPaymentDateMismatch && (
              <DateRolloverNotification
                firstPaymentDate={scheduleState.generated!.first_payment_date}
              />
            )}
            {scheduleState.madeSelection && (
              <PaymentScheduleCalendar
                isLoading={!scheduleState.generatedPaymentSchedule}
                showChangeButton={false}
                showDisclaimer={false}
                first_payment_date={
                  scheduleState.generated?.first_payment_date || cachedSchedule?.first_payment_date
                }
                payment_cycle_due_date={
                  scheduleState.generated?.payment_cycle_due_date ||
                  cachedSchedule?.payment_cycle_due_date
                }
                schedule={scheduleState.generated?.schedule || cachedSchedule?.schedule}
                monthly_choice={
                  scheduleState.generated?.monthly_choice || cachedSchedule?.monthly_choice
                }
                withdraw_amount={
                  scheduleState.generated?.withdraw_amount || cachedSchedule?.withdraw_amount
                }
              />
            )}
            <RepaymentContinue
              disabled={!scheduleState.generatedPaymentSchedule}
              {...{ scheduleState }}
            />
          </Grid>
        </Grid>
      </Grid>
    </DivRoot>
  )
}
