import { Grid, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOriginationContext } from 'src/hooks'
import { StepsEnum } from 'src/types'
import { changeLoanDetailsClicked } from 'src/utils'

import { ArrowDownIcon, ArrowUpIcon, ChangeLink, RightBox, TableGridItemHeader } from '../styles'
import { DropDownContainerProps } from '../types'

export function DropDownContainer({ title, content }: DropDownContainerProps) {
  const { setStep } = useOriginationContext()
  const { t } = useTranslation()
  const changeLabel = t('ReviewOrder.change')
  const [hidden, setHidden] = useState(false)

  return (
    <>
      <Grid item xs={12}>
        <TableGridItemHeader container spacing={0}>
          <Grid item xs={10} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography variant="body2">{title}</Typography>
            <IconButton
              disableRipple
              onClick={() => setHidden(!hidden)}
              style={{ padding: 0, paddingRight: '10px', marginTop: '-1px' }}
            >
              {hidden ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <RightBox>
              <ChangeLink
                onClick={() => {
                  setStep(StepsEnum.SELECT_OFFER)
                  changeLoanDetailsClicked(changeLabel, StepsEnum.ORDER_REVIEW)
                }}
              >
                {t('ReviewOrder.change')}
              </ChangeLink>
            </RightBox>
          </Grid>
        </TableGridItemHeader>
      </Grid>

      {!hidden && (
        <Grid container style={{ paddingTop: 0 }}>
          <Grid item xs={12}>
            {content}
          </Grid>
        </Grid>
      )}
    </>
  )
}
