import { createTheme } from '@mui/material/styles'

import Bogle from './fonts/Bogle.ttf'
declare module '@mui/material/styles' {
  interface TypographyVariants {
    heading: React.CSSProperties
    sectionHeading: React.CSSProperties
    subheading: React.CSSProperties
    body: React.CSSProperties
    buttonText: React.CSSProperties
    termsText: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    heading?: React.CSSProperties
    sectionHeading?: React.CSSProperties
    subheading?: React.CSSProperties
    body?: React.CSSProperties
    buttonText?: React.CSSProperties
    termsText?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    heading: true
    sectionHeading: true
    subheading: true
    body: true
    buttonText: true
    hover?: true
    termsText: true
  }
}

const COLORS = {
  white: '#FFFFFF',
  black: '#000000',
  walmartBlue: '#0071DC',
  darkGreen: '#005151',
  darkGrey: '#4B5563',
  neutralGrey: '#F3F4F6',
  lightGrey: '#D1D5DB',
  lightBlue: '#E6F1FC',
}

// @ts-ignore
const WalmartLandingTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: COLORS.walmartBlue,
      light: COLORS.white,
    },
    secondary: {
      main: COLORS.darkGreen,
      light: COLORS.neutralGrey,
    },
    info: {
      main: COLORS.lightBlue,
    },

    background: { default: COLORS.lightGrey },
    text: {
      primary: COLORS.black,
    },
  },
  typography: {
    fontFamily: 'Bogle',
    fontWeightRegular: 400,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    heading: {
      fontFamily: 'Bogle',
      fontStyle: 'normal',
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 700,
      color: COLORS.black,
      marginBottom: '25px',
    },
    sectionHeading: {
      fontFamily: 'Bogle',
      fontStyle: 'normal',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '30px',
      color: COLORS.black,
      marginBottom: '25px',
    },
    subheading: {
      fontFamily: 'Bogle',
      fontStyle: 'normal',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '30px',
      color: COLORS.black,
      marginBottom: '25px',
    },
    body: {
      fontFamily: 'Bogle',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
      color: COLORS.black,
      marginBottom: '25px',
    },
    buttonText: {
      fontFamily: 'Bogle',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20px',
      color: COLORS.white,
      textTransform: 'none',
    },
    termsText: {
      fontFamily: 'Bogle',
      fontStyle: 'normal',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
      color: COLORS.darkGrey,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          heading: 'h2',
          sectionHeading: 'h5',
          subheading: 'h6',
          body: 'p',
          buttonText: 'p',
          termsText: 'p',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Bogle'
        src: local('Bogle'), url(${Bogle}) format('truetype')
        font-style: normal;
        font-display: swap;
      }
    `,
    },
  },
})

export default WalmartLandingTheme
