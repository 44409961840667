import { Grid, GridProps, useMediaQuery, useTheme } from '@mui/material'
import { ReactNode } from 'react'

interface SectionContainerProps extends GridProps {
  children: ReactNode
}
export const SectionContainer = ({ children, ...props }: SectionContainerProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container mx={isMobile ? '16px' : '40px'} my={isMobile ? '24px' : '50px'} {...props}>
      {children}
    </Grid>
  )
}
