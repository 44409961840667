import { Box, Grid, Radio, Typography, AccordionSummary } from '@mui/material'
import { styled } from '@mui/material/styles'
import CircleIcon from '@mui/icons-material/Circle'

import theme from 'src/themes'

import { BoxModalProps, BulletListItemProps } from './types'

import { DivRoot } from '../common/DivRoot'

export const TermsImgStyle = styled('img')({
  paddingLeft: '10px',
  width: '174px',
  height: '160px',
})
export const GridImage = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const DivStyle = styled(DivRoot)<{ isMobile?: boolean }>(({ isMobile }) => ({
  maxHeight: isMobile ? '100vh' : '90vh',
  overflowX: 'hidden',
  overflowY: 'scroll',
  scrollBehavior: 'smooth',
}))

export const ModalCloseButton = styled(Grid)<{ isMobile?: boolean }>(({ isMobile }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  right: isMobile ? '1.5%' : '3%',
  top: '2%',
  zIndex: '1',
  cursor: 'pointer',
}))

export const MainPaymentProtectionListItem: React.FC<BulletListItemProps> = ({
  text,
  isMobile,
}) => {
  return (
    <Grid container sx={{ paddingLeft: isMobile ? '24px' : '48px' }}>
      <CircleIcon
        sx={{
          fontSize: '6px',
          color: theme.color.grey8,
          marginRight: '5px',
          display: 'inline',
          marginTop: '6px',
        }}
      />
      <Grid
        item
        xs={11}
        sx={{
          paddingRight: '0px',
          display: 'inline',
          alignItems: 'flex-start',
          paddingBottom: '8px',
          lineHeight: '0px',
        }}
      >
        <Typography variant="body3" paddingLeft="0px" sx={{ color: theme.color.grey9 }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const TermsPageListItem: React.FC<BulletListItemProps> = ({ text }) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={0.5} sx={{ paddingRight: '8px' }}>
        <CircleIcon
          sx={{
            fontSize: '6px',
            color: theme.color.grey8,
            marginRight: '5px',
            display: 'inline',
            marginBottom: '3px',
          }}
        />
      </Grid>
      <Grid
        item
        xs={11.5}
        sx={{
          paddingLeft: '0px',
          alignItems: 'flex-start',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          paddingBottom: '4px',
        }}
      >
        <Typography variant="subtitle2" paddingLeft="0px" alignSelf="stretch" display="inline">
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const FAQPageListItem: React.FC<BulletListItemProps> = ({ text }) => {
  return (
    <Grid container>
      <Grid item sx={{ paddingRight: '8px' }}>
        <CircleIcon
          sx={{
            fontSize: '5px',
            color: theme.color.grey8,
            marginRight: '5px',
            display: 'inline',
            marginBottom: '5px',
          }}
        />
      </Grid>
      <Grid
        item
        xs={11}
        sx={{
          paddingLeft: '0px',
          display: 'flex',
          alignItems: 'flex-start',
          wordBreak: 'break-word',
        }}
      >
        <Typography variant="h5" paddingLeft="0px" paddingBottom="8px">
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const FAQPageListFirstAccordion: React.FC<BulletListItemProps> = ({ title, content }) => {
  return (
    <Grid container justifyContent="flex-end">
      <Grid item xs={0.3}>
        <CircleIcon
          sx={{
            fontSize: '5px',
            color: theme.color.grey8,
            marginRight: '5px',
            display: 'inline',
            marginBottom: '3px',
          }}
        />
      </Grid>
      <Grid item xs={11.5}>
        <Typography display="inline" variant="h5" fontWeight="bold" paddingBottom="8px">
          {title}
        </Typography>
        <Typography display="inline" variant="h5" textAlign="left">
          {content}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const FAQPageSecondQuestionAccordion: React.FC<BulletListItemProps> = ({
  title,
  content1,
  content2,
}) => {
  return (
    <Grid container paddingBottom="8px" lineHeight="0px">
      <Grid item sx={{ paddingRight: '8px', marginTop: '6px' }}>
        <CircleIcon
          sx={{
            fontSize: '5px',
            color: theme.color.grey8,
            marginRight: '5px',
            display: 'inline',
            marginBottom: '5px',
          }}
        />
      </Grid>
      <Grid
        item
        xs={11}
        sx={{
          paddingLeft: '0px',
          alignItems: 'flex-start',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          overflowWrap: 'break-word',
        }}
      >
        <Typography display="inline" variant="h5" textAlign="left">
          {title}
        </Typography>
        <Typography display="inline" variant="h5" fontWeight="bold" textAlign="left">
          {content1}
        </Typography>
        <Typography display="inline" variant="h5" textAlign="left">
          {content2}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const RecommendedSectionHeader = styled(Grid)(({ theme }) => ({
  borderColor: theme.color.grey3,
  padding: '10px',
  borderWidth: '1px',
  borderBottom: 'none',
  borderBottomWidth: 0,
  borderRadius: '3px',
  backgroundColor: theme.color.brand5,
}))

export const RecommendedSectionHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.color.black,
  paddingLeft: '5px',
  fontWeight: 500,
}))

export const RecommendedSectionHeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const RecommendedSectionContainer = styled(Grid)(({ theme }) => ({
  borderColor: theme.color.grey3,
  padding: '10px',
  borderWidth: '1px',
  borderTop: 'none',
  borderBottomLeftRadius: '3px',
  borderBottomRightRadius: '3px',
  width: '100%',
  backgroundColor: 'rgba(236, 251, 247, 0.4)',
}))

export const RecommendedSectionContainerOne = styled(Grid)<{ checked: boolean }>(
  ({ theme, checked }) => ({
    borderColor: checked ? theme.color.brand8 : theme.color.grey3,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.color.brand8,
      borderWidth: '1px',
    },
  }),
)

export const RecommendedSectionHeaderSecond = styled(Grid)<{ checked: boolean }>(
  ({ theme, checked }) => ({
    borderColor: checked ? theme.color.brand8 : theme.color.grey3,
    padding: '10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.color.brand8,
      borderWidth: '1px',
    },
  }),
)

export const IconStyle = styled('img')({
  width: '40px',
  height: '40px',
  display: 'block',
  alignSelf: 'center',
})

export const TestimonyImgStyle = styled('img')({
  width: '40px',
  height: '40px',
  display: 'block',
  marginLeft: 'auto',
  marginBottom: 'auto',
  marginRight: 'auto',
})

export const AccordionHeaderStyle = styled('div')({
  padding: '0 10px',
})

export const RadioStyled = styled(Radio)({
  padding: '0',
  marginRight: 8,
  backgroundColor: theme.color.grey1,
  width: '20px',
  height: '20px',
})

export const ConsentTypography = styled(Typography)({
  fontWeight: 400,
  color: theme.color.grey7,
  fontSize: '12px',
  lineHeight: '18px',
  variantt: 'subtitle2',
  textAlign: 'left',
  paddingTop: '8px',
  paddingLeft: '32px',
})

export const BoxModal = styled(Box)<BoxModalProps>(({ isMobile }) => ({
  [theme.breakpoints.up('md')]: {
    width: 594,
  },
  width: isMobile ? '100%' : '75%',
  backgroundColor: 'white',
  border: 'solid 1px',
  borderColor: theme.color.grey4,
  borderRadius: '8px',
  margin: 0,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

export const FAQImgStyle = styled('img')({
  width: '160px',
  height: '160px',
})
export const FAQGridImage = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const FAQDivStyle = styled(DivStyle)({
  scrollSnapType: 'x mandatory',
})

export const ModalAccordionSummary = styled(AccordionSummary)<{ isMobile?: boolean }>(
  ({ isMobile }) => ({
    '& .MuiAccordionSummary-content': {
      flexDirection: isMobile ? 'column' : 'row',
    },
    padding: '24px 24px 16px 24px',
  }),
)

export const ModalAccordionGrid = styled(Grid)<{ isMobile?: boolean }>(({ isMobile }) => ({
  alignSelf: isMobile ? 'flex-start' : 'center',
  padding: isMobile ? '0 10px 10px 10px' : '0',
  flexDirection: isMobile ? 'column' : 'row',
}))
