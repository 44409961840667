import { Grid, Typography } from '@mui/material'
import { t } from 'i18next'

import { LoanDetailsSummary } from 'src/components/ReviewOrder/components/LoanDetailsSummary'
import { LoanDetailsText } from 'src/components/ReviewOrder/components/LoanDetailsText'
import { DropDownContainer } from 'src/components/ReviewOrder/Containers/DropDownContainer'
import { TableDivider } from 'src/components/ReviewOrder/styles'
import { useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { useScheduleHelper } from 'src/hooks/useScheduleHelper'
import { isQCResident } from 'src/utils/borrower'

export function LoanDetailsContainer() {
  const { currencyFormat, percentFormat } = useLocalizedFormatters()
  const { totalEstimatedInterest } = useScheduleHelper()

  const {
    setStep,
    cachedApplications,
    applicationId,
    bootstrapInfo: { borrower, application },
  } = useOriginationContext()

  const selectedLoanAmount = cachedApplications[applicationId]?.selected_loan_amount as number
  const selectedTerm = cachedApplications[applicationId]?.selected_offer_term as number
  const selectedApr = application?.apr as number

  const isQuebecResident = isQCResident(borrower)
  const loanAmount = currencyFormat(selectedLoanAmount, 2)
  const loanApr = percentFormat(selectedApr)

  const total = totalEstimatedInterest! + selectedLoanAmount
  const formattedTotalAmount = currencyFormat(total, 2)
  const formattedInterestAmount = currencyFormat(totalEstimatedInterest || 0, 2)

  return (
    <Grid container>
      <Grid item xs={12} marginBottom="8px" marginTop="24px">
        <Typography marginBottom="0px" variant="label">
          {t('ReviewOrder.loanDetails.title')}
        </Typography>
      </Grid>
      <DropDownContainer
        title={
          <LoanDetailsText
            isQuebecResident={isQuebecResident}
            loanAmount={loanAmount}
            loanApr={loanApr}
            loanTerm={selectedTerm}
          />
        }
        content={
          <LoanDetailsSummary
            selectedLoanAmount={loanAmount}
            formattedTotalAmount={formattedTotalAmount}
            formattedInterestAmount={formattedInterestAmount}
            isQuebecResident={isQuebecResident}
          />
        }
        setStep={setStep}
      />
      <Grid item xs={12}>
        <TableDivider />
      </Grid>
    </Grid>
  )
}
