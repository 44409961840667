import { Grid } from '@mui/material'
import { t } from 'i18next'

import FigTextField from 'src/components/common/FigTextField'
import { EmploymentStatus } from 'src/types'

interface OccupationIncomeSourceInputProps {
  employmentStatus: EmploymentStatus
  isEmployed: boolean
  isFromQC: boolean
  incomeSource: string
  setIncomeSource: (incomeSource: string) => void
}
export const OccupationIncomeSourceInput = (props: OccupationIncomeSourceInputProps) => {
  const { employmentStatus, isEmployed, isFromQC, incomeSource, setIncomeSource } = props
  if (!employmentStatus || isEmployed) {
    return null
  }
  return (
    <Grid item xs={12} marginBottom={'16px'}>
      <FigTextField
        onChange={e => {
          setIncomeSource(e.target.value)
        }}
        label={isFromQC ? t('Occupation.incomeSource.labelQC') : t('Occupation.incomeSource.label')}
        placeholder={t('Occupation.incomeSource.placeholder')}
        value={incomeSource}
      />
    </Grid>
  )
}
