import { useEffect } from 'react'

import { useOriginationContext } from 'src/hooks'
import { StepsEnum } from 'src/types'
import { creditRenewalsApplicationDeclinedPageLoaded } from 'src/utils'

/**
 * Custom hook for handling the declined state in the credit renewal process.
 *
 * This hook will track the page load event for the declined screen and returns the reason for the decline.
 *
 * @returns
 * - reason: The reason for the decline.
 * - screen: The current screen.
 */
export const useDeclined = () => {
  const screen = StepsEnum.DECLINED
  const {
    creditRenewalDecision: { decline_reasons },
  } = useOriginationContext()

  const reason = decline_reasons.includes('PAYMENT_PENDING') ? 'PAYMENT_PENDING' : 'REJECTED'

  useEffect(() => {
    creditRenewalsApplicationDeclinedPageLoaded(
      'Application Declined',
      StepsEnum.CREDIT_RENEWAL_DECLINED,
    )
  }, [])

  return { reason, screen }
}
