import { Grid } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

import FigTextField from 'src/components/common/FigTextField'

export const NameInput = ({ fullName }: { fullName: string }) => {
  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  return (
    <Grid item xs={12} my="16px" textAlign={'left'}>
      <FigTextField
        data-testid="confirm-name-input"
        value={fullName}
        label={t('CreditRenewals.ConfirmInformation.nameInput.label')}
        onClick={() => {
          setErrorState(true)
          setErrorMessage(t('CreditRenewals.ConfirmInformation.nameInput.errorMessage'))
        }}
        InputProps={{
          readOnly: true,
        }}
        helperText={errorState ? errorMessage : ''}
        error={errorState}
        errorMessage={errorMessage}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: errorState ? 'grey.400' : 'grey.400',
            },
            '& .MuiInputBase-input': {
              color: errorState ? 'grey.600' : 'grey.600',
            },
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'grey.600',
          },
          '& .MuiFormHelperText-root': {
            color: errorState ? 'error.main' : 'grey.600',
          },
        }}
      />
    </Grid>
  )
}
