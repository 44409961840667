import { captureMessage } from '@sentry/react'
import { addMonths, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { useApi, useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import {
  CachedApplications,
  MonthlyScheduleEnum,
  PaymentScheduleEnum,
  RepaymentScheduleRequestType,
  SavedRepaymentScheduleType,
} from 'src/types'
import { copy } from 'src/utils'
import { getDaysBetween } from 'src/utils/date'

function usePaymentScheduleText() {
  const { t } = useTranslation()
  const { dateFormatCustom } = useLocalizedFormatters()

  return ({
    frequency,
    monthlyChoice,
    paymentCycleDueDate,
  }: {
    frequency?: PaymentScheduleEnum
    monthlyChoice?: MonthlyScheduleEnum
    paymentCycleDueDate: Date | null
  }): string => {
    switch (frequency) {
      case PaymentScheduleEnum.EVERY_WEEK: {
        const dayOfWeek = dateFormatCustom(paymentCycleDueDate!, 'EEEE')
        return t('PaymentSchedule.scheduleHelpers.everyWeek', { dayOfWeek })
      }
      case PaymentScheduleEnum.EVERY_TWO_WEEKS:
        return t('PaymentSchedule.scheduleHelpers.everyTwoWeeks')
      case PaymentScheduleEnum.TWICE_A_MONTH:
        return t('PaymentSchedule.scheduleHelpers.twiceAMonth')
      case PaymentScheduleEnum.ONCE_A_MONTH:
        switch (monthlyChoice) {
          case MonthlyScheduleEnum.FIRST:
            return t('PaymentSchedule.scheduleHelpers.onceAMonth.first')
          case MonthlyScheduleEnum.LAST:
            return t('PaymentSchedule.scheduleHelpers.onceAMonth.last')
          case MonthlyScheduleEnum.FIFTEENTH:
            return t('PaymentSchedule.scheduleHelpers.onceAMonth.fifteenth')
          case MonthlyScheduleEnum.OTHER: {
            const day = dateFormatCustom(paymentCycleDueDate!, 'do')
            return t('PaymentSchedule.scheduleHelpers.onceAMonth.other', { day })
          }
          default:
            captureMessage(`Error generating first payment date monthly text `, {
              level: 'warning',
              extra: { monthlyChoice, paymentCycleDueDate },
            })
            return ''
        }
      default:
        captureMessage(`Error generating first payment date text with data`, {
          level: 'warning',
          extra: { frequency, paymentCycleDueDate, monthlyChoice },
        })
        return ''
    }
  }
}

/**
 *
 */
export const useScheduleHelper = () => {
  const { cachedApplications, applicationId, setCachedApplications } = useOriginationContext()
  const { currencyFormatFromStr } = useLocalizedFormatters()
  const getPaymentScheduleText = usePaymentScheduleText()

  const cachedSchedule = cachedApplications[applicationId]?.saved_repayment_schedule

  const frequency = cachedSchedule?.schedule as PaymentScheduleEnum | undefined
  const monthlyChoice = cachedSchedule?.monthly_choice as MonthlyScheduleEnum | undefined
  const widthdrawAmount = cachedSchedule?.withdraw_amount

  const totalEstimatedInterestStr = cachedSchedule?.total_estimated_interest
  const totalEstimatedInterest = totalEstimatedInterestStr
    ? parseFloat(cachedSchedule?.total_estimated_interest)
    : undefined

  const paymentCycleDueDateStr = cachedSchedule?.payment_cycle_due_date
  const paymentCycleDueDate = paymentCycleDueDateStr ? parseISO(paymentCycleDueDateStr) : null

  const firstPaymentDateStr = cachedSchedule?.first_payment_date
  const firstPaymentDate = firstPaymentDateStr ? parseISO(firstPaymentDateStr) : null

  const withdrawAmount = cachedSchedule?.withdraw_amount
  const displayWithdrawAmount = currencyFormatFromStr(withdrawAmount)

  const firstPaymentDateMismatch =
    firstPaymentDateStr && paymentCycleDueDateStr && firstPaymentDateStr !== paymentCycleDueDateStr

  const { generateRepaymentSchedule } = useApi()
  const { bootstrapInfo } = useOriginationContext()

  // Note: a schedule is stale if system date is less than 7 days from the payment cycle due date
  const isScheduleStale = paymentCycleDueDate
    ? getDaysBetween(new Date(bootstrapInfo!.system_date!), paymentCycleDueDate!) < 7
    : false
  const shouldRefechPaymentSchedule = !cachedSchedule || isScheduleStale

  const saveSchedule = ({
    schedule,
    monthly_choice,
    withdraw_amount,
    total_estimated_interest,
    first_payment_date,
    payment_cycle_due_date,
  }: SavedRepaymentScheduleType) => {
    const newCachedApplications = copy(cachedApplications) as CachedApplications
    newCachedApplications[applicationId].saved_repayment_schedule = {
      withdraw_amount,
      total_estimated_interest,
      first_payment_date,
      monthly_choice,
      schedule,
      payment_cycle_due_date,
    }
    setCachedApplications(newCachedApplications)
  }

  const generateAndSaveSchedule = async (scheduleOptions?: RepaymentScheduleRequestType) => {
    const defaultOptions = {
      schedule: PaymentScheduleEnum.ONCE_A_MONTH,
      monthly_choice: MonthlyScheduleEnum.OTHER,
      first_payment_date: addMonths(parseISO(bootstrapInfo!.system_date!), 1).toISOString(),
    }
    const result = await generateRepaymentSchedule(scheduleOptions || defaultOptions)
    saveSchedule(result.data)
  }

  return {
    frequency,
    monthlyChoice,
    firstPaymentDateStr,
    firstPaymentDate,
    paymentCycleDueDate,
    withdrawAmount,
    displayWithdrawAmount,
    widthdrawAmount,
    totalEstimatedInterest,
    firstPaymentDateMismatch,
    getPaymentScheduleText,
    saveSchedule,
    shouldRefechPaymentSchedule,
    generateAndSaveSchedule,
    cachedSchedule,
    paymentCycleDueDateStr,
  }
}
