import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ApplyNowButton } from 'src/components/EndToEnd/landing/Walmart/components/ApplyNowButton'
import { CardItem } from 'src/components/EndToEnd/landing/Walmart/components/CardItem'
import { RateDisclaimer } from 'src/components/EndToEnd/landing/Walmart/components/RateDisclaimer'
import { SectionContainer } from 'src/components/EndToEnd/landing/Walmart/components/SectionContainer'
import {
  ApplyNowButtonSizes,
  WalmartLandingPageSections,
} from 'src/components/EndToEnd/landing/Walmart/types'
import { cardSectionFirstImage, cardSectionSecondImage, cardSectionThirdImage } from 'src/images'

export const CardSection = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <SectionContainer justifyContent={isMobile ? 'center' : 'left'}>
      <Grid item mb="20px">
        <Typography variant="sectionHeading" textAlign={'center'}>
          {t('e2e.Landing.walmart.cardSection.title')}
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between">
        <CardItem
          imgSrc={cardSectionFirstImage}
          title={t('e2e.Landing.walmart.cardSection.first.title')}
          content={t('e2e.Landing.walmart.cardSection.first.content')}
        />
        <CardItem
          imgSrc={cardSectionSecondImage}
          title={t('e2e.Landing.walmart.cardSection.second.title')}
          content={t('e2e.Landing.walmart.cardSection.second.content')}
          sx={isMobile ? { marginTop: '20px', marginBottom: '20px' } : {}}
        />
        <CardItem
          imgSrc={cardSectionThirdImage}
          title={t('e2e.Landing.walmart.cardSection.third.title')}
          content={t('e2e.Landing.walmart.cardSection.third.content')}
        />
      </Grid>
      <Grid container justifyContent="center" alignItems="center" textAlign="center" mt="30px">
        <Grid item>
          <ApplyNowButton
            size={ApplyNowButtonSizes.LARGE}
            sectionName={WalmartLandingPageSections.CardSection}
          />
          <RateDisclaimer />
        </Grid>
      </Grid>
    </SectionContainer>
  )
}
