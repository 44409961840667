import { WarningAmberOutlined } from '@mui/icons-material'
import { Grid, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import theme from 'src/themes'
import { StepsEnum } from 'src/types'
import { loanPurposeInvestmentScamArticleLinkClicked } from 'src/utils'

export const InvestmentScamWarning = () => {
  const { t } = useTranslation()

  return (
    <Grid container xs={12} padding="16px" sx={{ backgroundColor: theme.color.warning3 }}>
      <Grid item xs={1}>
        <WarningAmberOutlined style={{ color: theme.color.black }} />
      </Grid>
      <Grid item xs={11}>
        <Typography variant="body3" color={theme.color.black} sx={{ fontWeight: '400' }}>
          {t('PurposeOfLoan.investmentScamMessage')}
        </Typography>
        <Typography
          variant="body3"
          color={theme.color.black}
          sx={{ fontWeight: 'bold', display: 'inline' }}
        >
          <br />
          {t('PurposeOfLoan.investmentScamWarningMessage')}
        </Typography>
        <Link
          display={'inline'}
          whiteSpace="nowrap"
          sx={{ textDecoration: 'underline', fontWeight: 'light', fontSize: '14px' }}
          onClick={() => {
            loanPurposeInvestmentScamArticleLinkClicked(
              'Investment Scam Article Link',
              StepsEnum.LOAN_PURPOSE,
            )
          }}
          href={t('PurposeOfLoan.investmentScamArticleLink')}
          target="_blank"
        >
          {t('PurposeOfLoan.investmentScamLinkMessage')}
        </Link>
      </Grid>
    </Grid>
  )
}
