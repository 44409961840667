import { Grid } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FigDropdown, FigMenuItem } from 'src/components/common/FigDropdown'
import FigTextField from 'src/components/common/FigTextField'

export const PurposeOfLoanInput = ({ handleInput }: { handleInput: (e: any) => void }) => {
  const { t } = useTranslation()
  const otherCategory = 'Other'

  const purposeData = [
    {
      value: 'Choose an option',
      displayName: t('PurposeOfLoan.displayNames.chooseAnOption'),
    },
    {
      value: 'Debt Consolidation',
      displayName: t('PurposeOfLoan.displayNames.debt'),
    },
    {
      value: 'Expenses',
      displayName: t('PurposeOfLoan.displayNames.bills'),
    },
    {
      value: 'Home Improvement',
      displayName: t('PurposeOfLoan.displayNames.home'),
    },
    {
      value: 'Emergency',
      displayName: t('PurposeOfLoan.displayNames.emergency'),
    },
    {
      value: 'Automobile Financing / Repair',
      displayName: t('PurposeOfLoan.displayNames.auto'),
    },
    {
      value: 'Education',
      displayName: t('PurposeOfLoan.displayNames.education'),
    },
    {
      value: 'Personal Investment',
      displayName: t('PurposeOfLoan.displayNames.personalInvestment'),
    },
    {
      value: otherCategory,
      displayName: t('PurposeOfLoan.displayNames.other'),
    },
  ]

  const [purposeOfLoan, setPurposeOfLoan] = useState(purposeData[0].value)
  const [otherPurpose, setOtherPurpose] = useState('')

  return (
    <Grid item xs={12} data-testid="confirm-purpose-of-loan-input">
      <FigDropdown
        placeholder={purposeData[0].displayName}
        onChange={e => {
          setPurposeOfLoan(e.target.value as string)
          handleInput(e.target.value as string)
        }}
        value={purposeOfLoan}
        label={t('CreditRenewals.ConfirmInformation.purposeOfLoanInput.label')}
        menuItems={purposeData.map(obj => (
          <FigMenuItem key={obj.displayName} value={obj.value}>
            {obj.displayName}
          </FigMenuItem>
        ))}
      />
      {purposeOfLoan == otherCategory && (
        <Grid item xs={12} my="16px">
          <FigTextField
            value={otherPurpose}
            onChange={e => {
              setOtherPurpose(e.target.value)
              handleInput(`${purposeOfLoan} - ${e.target.value}`)
            }}
            label={t('PurposeOfLoan.otherReasonLabel')}
          />
        </Grid>
      )}
    </Grid>
  )
}
