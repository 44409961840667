import { OccupationEmployerInput } from 'src/components/CreditRenewal/components/ConfirmInformation/Occupation/OccupationEmployerInput'
import { OccupationIncomeSourceInput } from 'src/components/CreditRenewal/components/ConfirmInformation/Occupation/OccupationIncomeSourceInput'
import { OccupationStatusInput } from 'src/components/CreditRenewal/components/ConfirmInformation/Occupation/OccupationStatusInput'
import { useOccupationInput } from 'src/components/CreditRenewal/hooks/useOccupationInput'
import { OccupationEmploymentInputProps } from 'src/types'

export const OccupationEmploymentInput = ({
  employmentData,
  borrower,
  handleOccupationEmploymentStatus,
  handleOccupationEmployerName,
  handleOccupationInput,
  handleOccupationIncomeSource,
  handleOccupationStartDate,
}: OccupationEmploymentInputProps) => {
  const { occupationStatusProps, ocucpationEmployerProps, occupationIncomeSourceProps } =
    useOccupationInput({
      employmentData,
      borrower,
      handleOccupationEmploymentStatus,
      handleOccupationEmployerName,
      handleOccupationInput,
      handleOccupationIncomeSource,
      handleOccupationStartDate,
    })

  return (
    <>
      <OccupationStatusInput {...occupationStatusProps} />
      <OccupationEmployerInput {...ocucpationEmployerProps} />
      <OccupationIncomeSourceInput {...occupationIncomeSourceProps} />
    </>
  )
}
