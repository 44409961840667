import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { PaymentScheduleCalendar } from 'src/components/PaymentSchedule/shared/PaymentScheduleCalendar'
import { BackButton, PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useOriginationContext } from 'src/hooks'
import { useScheduleHelper } from 'src/hooks/useScheduleHelper'
import { StepsEnum } from 'src/types/common'
import { confirmPaymentScheduleBackClicked, scheduleContinueClicked } from 'src/utils'

interface ConfirmPaymentScheduleProps {
  previousStep: StepsEnum
  nextStep: StepsEnum
}

function ConfirmPaymentSchedule({ nextStep, previousStep }: ConfirmPaymentScheduleProps) {
  const { setStep, asyncRequestsInProgress, isCheckingAppStatus } = useOriginationContext()

  const { t } = useTranslation()

  const {
    cachedSchedule,
    frequency,
    monthlyChoice,
    paymentCycleDueDateStr,
    shouldRefechPaymentSchedule,
    generateAndSaveSchedule,
  } = useScheduleHelper()

  const continueLabel = t('common.continueLabel')

  const handleContinue = () => {
    scheduleContinueClicked(
      continueLabel,
      StepsEnum.REPAYMENT_SCHEDULE_CONFIRM,
      cachedSchedule!.schedule,
    )
    setStep(nextStep)
  }

  const handleCancel = () => {
    confirmPaymentScheduleBackClicked(StepsEnum.REPAYMENT_SCHEDULE_CONFIRM)
    setStep(previousStep)
  }

  const { isFetching } = useQuery<Promise<void>>(
    ['generatePaymentSchedule'],
    () =>
      generateAndSaveSchedule({
        schedule: frequency,
        monthly_choice: monthlyChoice,
        first_payment_date: paymentCycleDueDateStr!,
      }),
    {
      enabled: shouldRefechPaymentSchedule,
    },
  )

  if (asyncRequestsInProgress.saveOffer || isCheckingAppStatus) {
    return <LoadingAnimation />
  }

  return (
    <DivRoot>
      <Typography variant="h1" textAlign="center" marginBottom="20px">
        {t('PaymentSchedule.ConfirmPaymentSchedule.title')}
      </Typography>
      <PaymentScheduleCalendar
        isLoading={isFetching}
        showChangeButton={true}
        showDisclaimer={true}
        first_payment_date={cachedSchedule?.first_payment_date}
        payment_cycle_due_date={cachedSchedule?.payment_cycle_due_date}
        schedule={cachedSchedule?.schedule}
        monthly_choice={cachedSchedule?.monthly_choice}
        withdraw_amount={cachedSchedule?.withdraw_amount}
      />
      <Grid item xs={12} display="flex" justifyContent="center" id="continueBtn">
        <PrimaryCTA buttonText={continueLabel} onClick={handleContinue} disabled={isFetching} />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
        <BackButton onClick={handleCancel} />
      </Grid>
    </DivRoot>
  )
}
export default ConfirmPaymentSchedule
