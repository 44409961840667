import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SectionContainer } from 'src/components/EndToEnd/landing/Walmart/components/SectionContainer'

export const FAQSection = () => {
  const { t } = useTranslation()
  const faqList = t('e2e.Landing.walmart.faqSection.faqList', { returnObjects: true })

  const faqListItems = faqList.map((faqItem: any, index: number) => {
    return (
      <Accordion disableGutters elevation={0} key={index}>
        <AccordionSummary
          sx={{ padding: 0 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
        >
          <Typography variant="subheading" fontWeight={400} marginBottom={0}>
            {faqItem.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Typography variant="body">{faqItem.content}</Typography>
        </AccordionDetails>
      </Accordion>
    )
  })

  return (
    <SectionContainer>
      <Grid item>
        <Typography variant="sectionHeading">
          {t('e2e.Landing.walmart.faqSection.title')}
        </Typography>
      </Grid>
      <Grid item>{faqListItems}</Grid>
    </SectionContainer>
  )
}
