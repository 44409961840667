import CloseIcon from '@mui/icons-material/Close'
import { Grid, IconButton, Link, Snackbar, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { LoanDetailsContainer } from 'src/components/ReviewOrder/Containers/LoanDetailsContainer'
import { PaymentContainer } from 'src/components/ReviewOrder/Containers/PaymentContainer'
import { PaymentProtectionPlanContainer } from 'src/components/ReviewOrder/Containers/PaymentProtectionPlanContainer'
import { RepaymentContainer } from 'src/components/ReviewOrder/Containers/RepaymentContainer'
import { useReviewOrder } from 'src/components/ReviewOrder/hooks/useReviewOrder'
import { TypographyDetail, ImgStyle } from 'src/components/ReviewOrder/styles'
import { AgreementTypeEnum, ReviewOrderProps } from 'src/components/ReviewOrder/types'
import { BackButton, PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { PolicyContainer } from 'src/components/common/Policy'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { reviewImg } from 'src/images'
import theme from 'src/themes'
import { StepsEnum } from 'src/types/common'
import { reviewLearnMoreClicked } from 'src/utils'

function ReviewOrder({ nextStep, previousStep }: ReviewOrderProps) {
  const { t } = useTranslation()

  const {
    eligibleForProtection,
    isCheckingAppStatus,
    isEnrolled,
    isAuthorizePaymentStartError,
    isPolicyIncomplete,
    consentEmailSource,
    incompleteError,
    hideSnackBar,
    setHideSnackBar,
    isLoadingLoanAgreement,
    isLoadingPadAgreement,
    paymentMethod,
    isGetPaymentLoading,
    prevFirstPaymentDate,
    isVerifyingRequest,
    fetchLoanAgreement,
    fetchPadAgreement,
    handleAgreementChecked,
    handleContinue,
    handlePrevious,
    consents,
    updateConsents,
  } = useReviewOrder()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    updateConsents()
  }, [])

  useEffect(() => {
    if (isAuthorizePaymentStartError) {
      const errorElement = document.getElementById('startDateErrorWrapper')
      errorElement?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isGetPaymentLoading, isAuthorizePaymentStartError])

  if (isCheckingAppStatus) {
    return <LoadingAnimation />
  }
  if (isVerifyingRequest) {
    return <LoadingAnimation subtitle={t('ReviewOrder.loading')} />
  }
  if (isGetPaymentLoading) {
    return <LoadingAnimation />
  }

  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12} paddingBottom="0px" marginBottom="8px">
          <ImgStyle src={reviewImg} alt="Clipboard review icon" isMobile={isMobile} />
          <Typography textAlign="center" variant="h1" marginTop="32px">
            {t('ReviewOrder.title')}
          </Typography>
          <Typography textAlign="center" variant="body2" marginTop="32px">
            {t('ReviewOrder.subtitle')}
          </Typography>
        </Grid>
      </Grid>
      <LoanDetailsContainer />
      {eligibleForProtection && (
        <Grid container>
          <PaymentProtectionPlanContainer isEnrolled={isEnrolled} />
        </Grid>
      )}
      <Grid container>
        <RepaymentContainer prevFirstPaymentDate={prevFirstPaymentDate} isEnrolled={isEnrolled} />
      </Grid>
      <PaymentContainer paymentMethod={paymentMethod} />
      <Grid container>
        <Box id="errorWrapper">
          {incompleteError && (
            <Typography component="span" variant="body2" color={theme.color.error} marginTop="16px">
              Please complete all the required fields
            </Typography>
          )}
        </Box>
        <PolicyContainer
          checked={consents.accept_toc}
          setChecked={(checked: boolean) => {
            handleAgreementChecked(checked, AgreementTypeEnum.TOC)
          }}
          content={
            <>
              <Typography color={theme.color.grey9} variant="body2">
                <Trans
                  i18nKey="ReviewOrder.consents.loanAgreementText"
                  values={{ loanAgreementLabel: t('ReviewOrder.consents.loanAgreementLabel') }}
                >
                  I agree to Fig’s{' '}
                  <Link variant="linkMedium" onClick={fetchLoanAgreement}>
                    t('ReviewOrder.consents.loanAgreementLabel')
                  </Link>
                  <Typography component="span" display="inline" variant="body2" color="primary">
                    *
                  </Typography>
                </Trans>
              </Typography>
              <TypographyDetail>{t('ReviewOrder.consents.loanAgreementFootnote')}</TypographyDetail>
            </>
          }
        />
        <PolicyContainer
          checked={consents.accept_pad}
          setChecked={(checked: boolean) => {
            handleAgreementChecked(checked, AgreementTypeEnum.PAD)
          }}
          content={
            <Typography color={theme.color.grey9} variant="body2">
              <Trans
                i18nKey="ReviewOrder.consents.padAgreementText"
                values={{ padAgreementLabel: t('ReviewOrder.consents.padAgreementLabel') }}
              >
                Fig can automatically withdraw payments that are due from my account according to
                the{' '}
                <Link variant="linkMedium" whiteSpace="nowrap" onClick={() => fetchPadAgreement()}>
                  {t('ReviewOrder.consents.padAgreementLabel')}
                </Link>
                <Typography component="span" display="inline" variant="body2" color="primary">
                  *
                </Typography>
              </Trans>
            </Typography>
          }
        />
        <PolicyContainer
          checked={consents.check_fico}
          setChecked={(checked: boolean) => {
            handleAgreementChecked(checked, AgreementTypeEnum.FICO)
          }}
          content={
            <Typography color={theme.color.grey9} variant="body2">
              <Trans
                i18nKey="ReviewOrder.consents.creditCheckText"
                values={{ learnMoreLabel: t('ReviewOrder.consents.learnMoreLabel') }}
              >
                <Link
                  variant="linkMedium"
                  href={t('HelpDesk.hardCutCheckUrl')}
                  target="_blank"
                  onClick={() => {
                    reviewLearnMoreClicked(
                      t('ReviewOrder.consents.learnMoreLabel'),
                      StepsEnum.ORDER_REVIEW,
                    )
                  }}
                >
                  {t('ReviewOrder.consents.learnMoreLabel')}
                </Link>
                <Typography component="span" display="inline" variant="body2" color="primary">
                  *
                </Typography>
              </Trans>
            </Typography>
          }
        />
        {(consentEmailSource !== StepsEnum.SELECT_OFFER || !consents.consent_email) && (
          <PolicyContainer
            checked={consents.consent_email}
            setChecked={(checked: boolean) => {
              handleAgreementChecked(checked, AgreementTypeEnum.EMAIL)
            }}
            content={
              <Trans i18nKey="ReviewOrder.consents.marketingText">
                <Typography color={theme.color.grey9} variant="body2">
                  Fig can occasionally send me electronic messages about its products and services
                  (optional)
                </Typography>
                <TypographyDetail>Don’t worry, we don’t like spam either</TypographyDetail>
              </Trans>
            }
          />
        )}
      </Grid>
      <Grid container marginTop="32px">
        <Grid item xs={12} id="startDateErrorWrapper">
          <Box>
            {isAuthorizePaymentStartError && (
              <Typography variant="body2" color={theme.color.error} marginBottom="8px">
                Your first payment date cannot be in the past. Please change your Payment Schedule
                above.
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <PrimaryCTA
            buttonText={t('ReviewOrder.confirmLoanLabel')}
            onClick={() => handleContinue(nextStep)}
            disabled={isPolicyIncomplete}
          />
        </Grid>
        <Grid item xs={12} textAlign="center" marginTop="16px" marginBottom="8px">
          <Typography variant="body2" color={theme.color.grey6}>
            {t('ReviewOrder.disclaimer')}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BackButton
            onClick={() => {
              handlePrevious(previousStep)
            }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={isLoadingLoanAgreement && !hideSnackBar}
        onClose={() => setHideSnackBar(true)}
        color={theme.color.grey9}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message={t('ReviewOrder.loadingLoanAgreement')}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setHideSnackBar(true)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Snackbar
        open={isLoadingPadAgreement && !hideSnackBar}
        onClose={() => setHideSnackBar(true)}
        color={theme.color.grey9}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message={t('ReviewOrder.loadingPad')}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setHideSnackBar(true)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </DivRoot>
  )
}

export default ReviewOrder
