import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { AddressInput } from 'src/components/CreditRenewal/components/ConfirmInformation/AddressInput'
import { DateOfBirthInput } from 'src/components/CreditRenewal/components/ConfirmInformation/DateOfBirthInput'
import { NameInput } from 'src/components/CreditRenewal/components/ConfirmInformation/NameInput'
import { OccupationEmploymentInput } from 'src/components/CreditRenewal/components/ConfirmInformation/Occupation/OccupationEmploymentInput'
import { PhoneNumberInput } from 'src/components/CreditRenewal/components/ConfirmInformation/PhoneNumberInput'
import { PurposeOfLoanInput } from 'src/components/CreditRenewal/components/ConfirmInformation/PurposeOfLoanInput'
import { useConfirmInformation } from 'src/components/CreditRenewal/hooks/useConfirmInformation'
import { StepsEnum } from 'src/types'
import { creditRenewalsConfirmInformationPageCTAClicked } from 'src/utils'

const ConfirmInformation = () => {
  const { t } = useTranslation()
  const {
    isLoading,
    applicantData,
    handlePhoneInput,
    handlePurposeOfLoanInput,
    handleConfirm,
    handleOccupationEmploymentStatus,
    handleOccupationEmployerName,
    handleOccupationStartDate,
    handleOccupationInput,
    handleOccupationIncomeSource,
    handleAddressInput,
    isConfirmDisabled,
  } = useConfirmInformation()

  if (isLoading) {
    return <LoadingAnimation />
  }
  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12} mb="16px" textAlign="center">
          <Typography variant="h1">{t('CreditRenewals.ConfirmInformation.title')}</Typography>
        </Grid>
        <NameInput fullName={applicantData.fullName} />
        <DateOfBirthInput dateOfBirth={applicantData.dateOfBirth} />
        <AddressInput handleAddressInput={handleAddressInput} />
        <PhoneNumberInput
          defaultPhoneNumber={applicantData.phoneNumber}
          handleInput={handlePhoneInput}
        />
        <OccupationEmploymentInput
          {...{
            handleOccupationEmploymentStatus,
            handleOccupationEmployerName,
            handleOccupationInput,
            handleOccupationIncomeSource,
            handleOccupationStartDate,
          }}
          employmentData={applicantData.employmentData}
          borrower={applicantData.borrower}
        />
        <PurposeOfLoanInput handleInput={handlePurposeOfLoanInput} />

        <Grid item xs={12} marginTop="32px" textAlign={'center'}>
          <PrimaryCTA
            sx={{ marginY: '24px' }}
            disabled={isConfirmDisabled}
            onClick={() => {
              creditRenewalsConfirmInformationPageCTAClicked(
                t('CreditRenewals.ConfirmInformation.buttonLabel'),
                StepsEnum.CREDIT_RENEWAL_CONFIRM_INFORMATION,
              )
              handleConfirm()
            }}
            buttonText={t('CreditRenewals.ConfirmInformation.buttonLabel')}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}

export default ConfirmInformation
