import { useContext, useEffect, useRef } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { debounce } from 'throttle-debounce'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { genericErrorImg } from 'src/images'
import { StepsEnum } from 'src/types'
import {
  errorContactFigClicked,
  errorLiveChatClicked,
  errorTryAgainClicked,
  isE2EUrl,
  parseUrl,
  segmentAnalytics,
  captureExceptionHelper,
} from 'src/utils'
import { useOriginationContext } from 'src/hooks'
import { PrequalificationContext } from 'src/contexts'
import ErrorPage from 'src/components/Error/shared/ErrorPage'

/**
 *
 */
export function GenericError({ isUncaughtError = true }: { isUncaughtError?: boolean }) {
  const { genericErrorPageError } = useOriginationContext()
  const { prequalGenericErrorPageError } = useContext(PrequalificationContext)
  const { t } = useTranslation()
  const { jwt, query } = parseUrl()
  const step = StepsEnum.ERROR

  const debouncedPageView = useRef(
    debounce(1000, (properties: any) => {
      segmentAnalytics.page(properties)
    }),
  )

  useEffect(() => {
    const error = isE2EUrl() ? prequalGenericErrorPageError : genericErrorPageError
    captureExceptionHelper('User navigated to the Generic Error page', error, 'warning')
  }, [prequalGenericErrorPageError, genericErrorPageError])

  useEffect(() => {
    window.onbeforeunload = () => {
      // pass
    }
    // If we have a handler here, the browser will show the dialogue "Reload site?"

    let url
    if (isUncaughtError) {
      if (isE2EUrl()) {
        url = `${window.location.origin}/#/${StepsEnum.END_TO_END}/${jwt}/${step}${query}`
      } else {
        url = `${window.location.origin}/#/${jwt}/${step}`
      }
      debouncedPageView.current({
        path: `/${step}`,
        title: document.title,
        referrer: url,
        url,
      })
    }
  }, [isUncaughtError])

  const tryAgain = () => {
    const { jwt, query } = parseUrl()
    let url
    if (isE2EUrl()) {
      url = `${window.location.origin}/#/${StepsEnum.END_TO_END}/${jwt}/${StepsEnum.PREQUALIFICATION}${query}`
    } else {
      url = `${window.location.origin}/#/${jwt}/${StepsEnum.LOAN_AMOUNT}${query}`
    }
    window.location.href = url
    if (isUncaughtError) {
      window.location.reload()
    }
    // If it is an uncaught error, we are not wrapped in the react router and need to reload,
    //  otherwise just changing the href will reroute without needing to reload
  }
  const content = (
    <Trans i18nKey="GenericError.content">
      <PleaseReachOut
        screen={step}
        liveChatAnalytics={errorLiveChatClicked}
        contactFigAnalytics={errorContactFigClicked}
      />
    </Trans>
  )
  const tryAgainButton = (
    <PrimaryCTA
      onClick={() => {
        errorTryAgainClicked(t('common.retryLabel'), step)
        tryAgain()
      }}
      buttonText={t('common.retryLabel')}
    />
  )

  return (
    <ErrorPage
      image={{ src: genericErrorImg, alt: t('GenericError.alt') }}
      title={t('GenericError.title')}
      content={content}
      buttons={[tryAgainButton]}
    />
  )
}
