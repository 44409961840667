import { useEffect, useRef } from 'react'

export default function TrustBox() {
  const ref = useRef(null)
  useEffect(() => {
    if ((window as any).Trustpilot) {
      ;(window as any).Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      data-locale="en-US"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="62d05fc7beb77e5c5fc0238b"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a href="https://www.trustpilot.com/review/fig.ca" target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  )
}
